import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listComplaints,
  createComplaint,
  updateComplaint,
  softDeleteComplaint,
  softDeleteMultipleComplaint,
  deleteComplaint,
  getComplaintAggregate,
  getComplaintById,
  getComplaintCount,
} from "../services/Complaint.service";

function useComplaintList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Complaint", { page, limit, $and, sort }], () =>
    listComplaints(args)
  );
}

function useComplaintCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createComplaint(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Complaint"]);

      const previousValue = queryClient.getQueryData(["Complaint"]) || [];

      queryClient.setQueryData(["Complaint"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Complaint"], previousValue),
    // After success or failure, refetch the Complaints query
    onSettled: () => {
      queryClient.invalidateQueries(["Complaint"]);
    },
  });
}

function useComplaintUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateComplaint(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Complaint"]);

      const previousValue = queryClient.getQueryData(["Complaint"]);

      queryClient.setQueryData(["Complaint"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Complaint"], previousValue),
    // After success or failure, refetch the Complaints query
    onSettled: () => {
      queryClient.invalidateQueries(["Complaint"]);
    },
  });
}

function useComplaintSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteComplaint(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Complaint"]);

      const previousValue = queryClient.getQueryData(["Complaint"]) || [];
      queryClient.setQueryData(["Complaint"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Complaint"], previousValue),
    // After success or failure, refetch the Complaints query
    onSettled: () => {
      queryClient.invalidateQueries(["Complaint"]);
    },
  });
}

function useComplaintMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleComplaint(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Complaint"]);

      const previousValue = queryClient.getQueryData(["Complaint"]) || [];
      queryClient.setQueryData(["Complaint"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Complaint"], previousValue),
    // After success or failure, refetch the Complaints query
    onSettled: () => {
      queryClient.invalidateQueries(["Complaint"]);
    },
  });
}

function useComplaintDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteComplaint(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Complaint"]);

      const previousValue = queryClient.getQueryData(["Complaint"]) || [];
      queryClient.setQueryData(["Complaint"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Complaint"], previousValue),
    // After success or failure, refetch the Complaints query
    onSettled: () => {
      queryClient.invalidateQueries(["Complaint"]);
    },
  });
}

function useComplaintCount() {
  return useQuery(["ComplaintCount"], () => {
    return getComplaintCount();
  });
}

function useComplaintAggregate(record) {
  return useQuery("Complaint", () => {
    return getComplaintAggregate(record);
  });
}

function useComplaintGetById(id) {
  return useQuery(["Complaint", id], () => {
    return getComplaintById(id);
  });
}

export {
  useComplaintList,
  useComplaintCreate,
  useComplaintUpdate,
  useComplaintDelete,
  useComplaintMultipleSoftDelete,
  useComplaintCount,
  useComplaintSoftDelete,
  useComplaintAggregate,
  useComplaintGetById,
};
