import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { questionaire } = API_URLS;

export const listQuestionaires = (payload) => {
  return apiClient({ url: questionaire.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createQuestionaire = (payload) => {
  return apiClient({ url: questionaire.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateQuestionaire = (payload) => {
  return apiClient({
    url: questionaire.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteQuestionaire = (payload) => {
  return apiClient({
    url: questionaire.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteQuestionaire = (payload) => {
  return apiClient({
    url: questionaire.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getQuestionaireCount = () => {
  return apiClient({ url: questionaire.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getQuestionaireAggregate = (payload) => {
  return apiClient({ url: questionaire.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getQuestionaireById = (payload) => {
  return apiClient({
    url: questionaire.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleQuestionaire = (payload) => {
  return apiClient({
    url: questionaire.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
