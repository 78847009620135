import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listDocuments,
  createDocument,
  updateDocument,
  softDeleteDocument,
  softDeleteMultipleDocument,
  deleteDocument,
  getDocumentAggregate,
  getDocumentById,
  getDocumentCount,
} from "../services/Document.service";

function useDocumentList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Document", { page, limit, $and, sort }], () =>
    listDocuments(args)
  );
}

function useDocumentCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createDocument(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Document"]);

      const previousValue = queryClient.getQueryData(["Document"]) || [];

      queryClient.setQueryData(["Document"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Document"], previousValue),
    // After success or failure, refetch the Documents query
    onSettled: () => {
      queryClient.invalidateQueries(["Document"]);
    },
  });
}

function useDocumentUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateDocument(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Document"]);

      const previousValue = queryClient.getQueryData(["Document"]);

      queryClient.setQueryData(["Document"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Document"], previousValue),
    // After success or failure, refetch the Documents query
    onSettled: () => {
      queryClient.invalidateQueries(["Document"]);
    },
  });
}

function useDocumentSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteDocument(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Document"]);

      const previousValue = queryClient.getQueryData(["Document"]) || [];
      queryClient.setQueryData(["Document"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Document"], previousValue),
    // After success or failure, refetch the Documents query
    onSettled: () => {
      queryClient.invalidateQueries(["Document"]);
    },
  });
}

function useDocumentMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleDocument(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Document"]);

      const previousValue = queryClient.getQueryData(["Document"]) || [];
      queryClient.setQueryData(["Document"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Document"], previousValue),
    // After success or failure, refetch the Documents query
    onSettled: () => {
      queryClient.invalidateQueries(["Document"]);
    },
  });
}

function useDocumentDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteDocument(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Document"]);

      const previousValue = queryClient.getQueryData(["Document"]) || [];
      queryClient.setQueryData(["Document"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Document"], previousValue),
    // After success or failure, refetch the Documents query
    onSettled: () => {
      queryClient.invalidateQueries(["Document"]);
    },
  });
}

function useDocumentCount() {
  return useQuery(["DocumentCount"], () => {
    return getDocumentCount();
  });
}

function useDocumentAggregate(record) {
  return useQuery("Document", () => {
    return getDocumentAggregate(record);
  });
}

function useDocumentGetById(id) {
  return useQuery(["Document", id], () => {
    return getDocumentById(id);
  });
}

export {
  useDocumentList,
  useDocumentCreate,
  useDocumentUpdate,
  useDocumentDelete,
  useDocumentMultipleSoftDelete,
  useDocumentCount,
  useDocumentSoftDelete,
  useDocumentAggregate,
  useDocumentGetById,
};
