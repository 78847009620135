import * as React from "react";
import ModelJsonSchema from "./Party.schema.json";
import mockData from "./data.json";
import { useBoolean } from "./../../hooks";
import { AddDrawer } from "../../components/common/AddDrawer";
import { EditDrawer } from "../../components/common/EditDrawer";
import { Confirmation } from "../../components/common/Confirmation";
import { errorToast, successToast } from "../../utils/notifications";
import PartyTable from "./PartyTable";
import { ACTION_TYPE, STORAGE_KEY } from "../../constant/common";
import { checkPermission } from "../../api/general";
import { isMocking } from "../../utils/utils";

const formSchema = {
  databaseType: process.env.REACT_APP_DATABASE_TYPE,
  screenLayout: ModelJsonSchema.screenLayout,
  attributes:
    ModelJsonSchema.actions.find((action) => action.category === "addEdit")
      ?.attributes || [],
};

const Party = ({ addRecord, editRecord, deleteRecord, deleteRecords, approveKYC, verifyKYCDocumentUpload }) => {
  const [addModal, showAddModal, hideAddModal] = useBoolean(false);
  const [editModal, showEditModal, hideEditModal] = useBoolean(false);
  const [deleteModal, showDeleteModal, hideDeleteModal] = useBoolean(false);
  const [actionType, setActionType] = React.useState(null);

  const currentRecord = React.useRef({});
  const selectedRows = React.useRef({});

  const onViewRecord = React.useCallback((record) => {
    record.id && window.open(`profile/${record.id}`, "_blank");
  }, []);

  const onDelete = React.useCallback((record) => {
    setActionType(ACTION_TYPE.DELETE);
    currentRecord.current = record;
    showDeleteModal();
  }, []);

  const onMultiDelete = React.useCallback((selectedIds) => {
    setActionType(ACTION_TYPE.MULTIDELETE);
    selectedRows.current = selectedIds;
    showDeleteModal();
  }, []);

  const onEdit = React.useCallback((record) => {
    currentRecord.current = record;
    showEditModal();
  }, []);

  const onApprove = React.useCallback((record) => {
    currentRecord.current = record;
    editRecord({
      id: currentRecord.current.id,
      isVerified: true
    });
  });

  const onBlueTickVerified = React.useCallback((record) => {
    currentRecord.current = record;
    editRecord({
      id: currentRecord.current.id,
      isBlueTickVerified: true,
      blueTickAt: new Date(),
      blueTickBy: localStorage.getItem(STORAGE_KEY.USER_ID)
    });
  });
  
  const onKYCVerified = React.useCallback((record) => {
    currentRecord.current = record;
    approveKYC({id: currentRecord.current.id})
  });

  const onKYCDocumentUpload = React.useCallback((record) => {
    currentRecord.current = record;
    verifyKYCDocumentUpload({id: currentRecord.current.id})
  });

  const onCustomActionClick = React.useCallback((actionName, record) => {
    alert(actionName + " action clicked");
  }, []);

  return (
    <React.Fragment>
      <PartyTable
        onApprove={onApprove}
        onBlueTickVerified={onBlueTickVerified}
        onKYCVerified={onKYCVerified}
        onKYCDocumentUploaded={onKYCDocumentUpload}
        mockData={mockData.data}
        onCustomActionClick={onCustomActionClick}
        onAdd={showAddModal}
        onEdit={onEdit}
        onDelete={onDelete}
        onView={onViewRecord}
        onMultiDelete={onMultiDelete}
        noAdd={
          !isMocking &&
          !checkPermission({
            permissionType: "C",
            modelName: ModelJsonSchema.name,
          })
        }
        noView={
          !isMocking &&
          !checkPermission({
            permissionType: "R",
            modelName: ModelJsonSchema.name,
          })
        }
        noEdit={
          !isMocking &&
          !checkPermission({
            permissionType: "U",
            modelName: ModelJsonSchema.name,
          })
        }
        noDelete={
          !isMocking &&
          !checkPermission({
            permissionType: "D",
            modelName: ModelJsonSchema.name,
          })
        }
      />

      <AddDrawer
        defaultValues={{
          role: 2,
        }}
        onSubmit={(record) => {
          addRecord(record)
            .then(successToast)
            .catch(errorToast)
            .finally(hideAddModal);
        }}
        schema={formSchema}
        open={addModal}
        onClose={hideAddModal}
      />
      <EditDrawer
        schema={formSchema}
        currentData={currentRecord.current}
        open={editModal}
        onClose={hideEditModal}
        onSubmit={(record) => {
          editRecord({ ...record, id: currentRecord.current.id })
            .then(successToast)
            .catch(errorToast)
            .finally(hideEditModal);
        }}
      />
      <Confirmation
        handleSubmit={() => {
          actionType === ACTION_TYPE.DELETE &&
            currentRecord.current.id &&
            deleteRecord({ id: currentRecord?.current?.id })
              .then(successToast)
              .catch(errorToast)
              .finally(hideDeleteModal);
          actionType === ACTION_TYPE.MULTIDELETE &&
            selectedRows.current &&
            deleteRecords({ ids: selectedRows.current })
              .then(successToast)
              .catch(errorToast)
              .finally(hideDeleteModal);
        }}
        handleCancel={hideDeleteModal}
        isOpen={deleteModal}
        OkText="Delete"
      />
    </React.Fragment>
  );
};

export default Party;
