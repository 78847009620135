import { apiClient } from "../api/client";
import { API_URLS } from "../api/config";
const { ProfileReporting } = API_URLS;

export const listProfileReportings = (payload) => {
  console.log("ProfileReporting.list",ProfileReporting.list)
  return apiClient({ url: ProfileReporting.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createProfileReporting = (payload) => {
  payload.isSubProfileReporting = false
  payload.isKYCDocumentUploaded = true
  payload.isKYC = true
  return apiClient({ url: ProfileReporting.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateProfileReporting = (payload) => {
  return apiClient({
    url: ProfileReporting.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteProfileReporting = (payload) => {
  return apiClient({
    url: ProfileReporting.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteProfileReporting = (payload) => {
  return apiClient({
    url: ProfileReporting.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProfileReportingCount = () => {
  return apiClient({ url: ProfileReporting.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProfileReportingAggregate = (payload) => {
  return apiClient({ url: ProfileReporting.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProfileReportingById = (payload) => {  
  return apiClient({
    url: ProfileReporting.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleProfileReporting = (payload) => {
  return apiClient({
    url: ProfileReporting.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const suspendProfileReporting = (payload) => {
  console.log("payload.id",payload.id)
  return apiClient({
    url: ProfileReporting.suspend_unSuspend,
    data: {userId:payload.id},
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't suspend/unsuspend record.");
    });
};

export const changeProfileReportingKYCStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: ProfileReporting.kycApprove + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeKYCDocumentStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: ProfileReporting.uploadKycDoc + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};