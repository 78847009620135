import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { party } = API_URLS;

export const listPartys = (payload) => {
  return apiClient({ url: party.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createParty = (payload) => {
  return apiClient({ url: party.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateParty = (payload) => {
  return apiClient({
    url: party.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteParty = (payload) => {
  return apiClient({
    url: party.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteParty = (payload) => {
  return apiClient({
    url: party.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartyCount = () => {
  return apiClient({ url: party.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartyAggregate = (payload) => {
  return apiClient({ url: party.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartyById = (payload) => {
  return apiClient({
    url: party.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleParty = (payload) => {
  return apiClient({
    url: party.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changePartyKYCStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: party.kycApprove + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeKYCDocumentStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: party.uploadKycDoc + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};