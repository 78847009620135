
    import React from 'react';
    import { usePartyCreate, usePartySoftDelete,usePartyMultipleSoftDelete, usePartyUpdate,useApprovePartyKYC,useUpdateKYCDocument } from '../../queries/Party.queries';
    import Party from './Party';
    
    const PartyContainer = () => {
    
      const {mutate:addRecord} = usePartyCreate();
      const {mutate:editRecord} = usePartyUpdate();
      const {mutate:deleteRecord} = usePartySoftDelete();
      const {mutate:deleteRecords} = usePartyMultipleSoftDelete();
      const {mutate:approveKYC} = useApprovePartyKYC();
      const {mutate:verifyKYCDocumentUpload} = useUpdateKYCDocument();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onApproveKYC = (record) => {
        return new Promise((resolve, reject) => {
          approveKYC(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onKYCDocumentUpload = (record) => {
        return new Promise((resolve, reject) => {
          console.log("sgr")
          verifyKYCDocumentUpload(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <Party 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
          approveKYC={onApproveKYC}
          verifyKYCDocumentUpload ={onKYCDocumentUpload}
        />
      )
    }
    
    export default PartyContainer;
    