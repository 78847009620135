import { apiClient } from "../api/client";
import { API_URLS } from "../api/config";
const { ReportReason } = API_URLS;

export const listReportReasons = (payload) => {
  console.log("ReportReason.list",ReportReason.list)
  return apiClient({ url: ReportReason.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createReportReason = (payload) => {
  payload.isSubReportReason = false
  payload.isKYCDocumentUploaded = true
  payload.isKYC = true
  return apiClient({ url: ReportReason.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateReportReason = (payload) => {
  return apiClient({
    url: ReportReason.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteReportReason = (payload) => {
  return apiClient({
    url: ReportReason.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteReportReason = (payload) => {
  return apiClient({
    url: ReportReason.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getReportReasonCount = () => {
  return apiClient({ url: ReportReason.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getReportReasonAggregate = (payload) => {
  return apiClient({ url: ReportReason.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getReportReasonById = (payload) => {  
  return apiClient({
    url: ReportReason.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleReportReason = (payload) => {
  return apiClient({
    url: ReportReason.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const suspendReportReason = (payload) => {
  console.log("payload.id",payload.id)
  return apiClient({
    url: ReportReason.suspend_unSuspend,
    data: {userId:payload.id},
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't suspend/unsuspend record.");
    });
};

export const changeReportReasonKYCStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: ReportReason.kycApprove + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeKYCDocumentStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: ReportReason.uploadKycDoc + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};