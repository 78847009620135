import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listCommentss,
  createComments,
  updateComments,
  softDeleteComments,
  softDeleteMultipleComments,
  deleteComments,
  getCommentsAggregate,
  getCommentsById,
  getCommentsCount,
} from "../services/Comments.service";

function useCommentsList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Comments", { page, limit, $and, sort }], () =>
    listCommentss(args)
  );
}

function useCommentsCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createComments(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Comments"]);

      const previousValue = queryClient.getQueryData(["Comments"]) || [];

      queryClient.setQueryData(["Comments"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Comments"], previousValue),
    // After success or failure, refetch the Commentss query
    onSettled: () => {
      queryClient.invalidateQueries(["Comments"]);
    },
  });
}

function useCommentsUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateComments(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Comments"]);

      const previousValue = queryClient.getQueryData(["Comments"]);

      queryClient.setQueryData(["Comments"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Comments"], previousValue),
    // After success or failure, refetch the Commentss query
    onSettled: () => {
      queryClient.invalidateQueries(["Comments"]);
    },
  });
}

function useCommentsSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteComments(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Comments"]);

      const previousValue = queryClient.getQueryData(["Comments"]) || [];
      queryClient.setQueryData(["Comments"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Comments"], previousValue),
    // After success or failure, refetch the Commentss query
    onSettled: () => {
      queryClient.invalidateQueries(["Comments"]);
    },
  });
}

function useCommentsMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleComments(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Comments"]);

      const previousValue = queryClient.getQueryData(["Comments"]) || [];
      queryClient.setQueryData(["Comments"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Comments"], previousValue),
    // After success or failure, refetch the Commentss query
    onSettled: () => {
      queryClient.invalidateQueries(["Comments"]);
    },
  });
}

function useCommentsDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteComments(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Comments"]);

      const previousValue = queryClient.getQueryData(["Comments"]) || [];
      queryClient.setQueryData(["Comments"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Comments"], previousValue),
    // After success or failure, refetch the Commentss query
    onSettled: () => {
      queryClient.invalidateQueries(["Comments"]);
    },
  });
}

function useCommentsCount() {
  return useQuery(["CommentsCount"], () => {
    return getCommentsCount();
  });
}

function useCommentsAggregate(record) {
  return useQuery("Comments", () => {
    return getCommentsAggregate(record);
  });
}

function useCommentsGetById(id) {
  return useQuery(["Comments", id], () => {
    return getCommentsById(id);
  });
}

export {
  useCommentsList,
  useCommentsCreate,
  useCommentsUpdate,
  useCommentsDelete,
  useCommentsMultipleSoftDelete,
  useCommentsCount,
  useCommentsSoftDelete,
  useCommentsAggregate,
  useCommentsGetById,
};
