import { combineReducers } from "@reduxjs/toolkit";
import Wef from "./WefReducer";
import UserActivity from "./UserActivityReducer";
import User from "./UserReducer";
import State from "./StateReducer";
import Questionaire from "./QuestionaireReducer";
import ProjectConfig from "./ProjectConfigReducer";
import Posts from "./PostsReducer";
import PartySurvey from "./PartySurveyReducer";
import Party from "./PartyReducer";
import Master from "./MasterReducer";
import Follow from "./FollowReducer";
import Event from "./EventReducer";
import Document from "./DocumentReducer";
import Contract from "./ContractReducer";
import Complaint from "./ComplaintReducer";
import Committee from "./CommitteeReducer";
import Comments from "./CommentsReducer";
import City from "./CityReducer";
import Area from "./AreaReducer";
import AppVersion from "./AppVersionReducer";
import Handler from "./HandlerResucer";

export default combineReducers({
  AppVersion,
  Area,
  City,
  Comments,
  Committee,
  Complaint,
  Contract,
  Document,
  Event,
  Follow,
  Master,
  Party,
  PartySurvey,
  Posts,
  ProjectConfig,
  Questionaire,
  State,
  User,
  UserActivity,
  Wef,
  Handler,
});
