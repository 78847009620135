import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const PostsReducer = createSlice({
  name: "Posts",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("PostsReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = PostsReducer;
export const { defaultAction } = actions;
export default reducer;
