import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listQuestionaires,
  createQuestionaire,
  updateQuestionaire,
  softDeleteQuestionaire,
  softDeleteMultipleQuestionaire,
  deleteQuestionaire,
  getQuestionaireAggregate,
  getQuestionaireById,
  getQuestionaireCount,
} from "../services/Questionaire.service";

function useQuestionaireList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Questionaire", { page, limit, $and, sort }], () =>
    listQuestionaires(args)
  );
}

function useQuestionaireCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createQuestionaire(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Questionaire"]);

      const previousValue = queryClient.getQueryData(["Questionaire"]) || [];

      queryClient.setQueryData(["Questionaire"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Questionaire"], previousValue),
    // After success or failure, refetch the Questionaires query
    onSettled: () => {
      queryClient.invalidateQueries(["Questionaire"]);
    },
  });
}

function useQuestionaireUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateQuestionaire(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Questionaire"]);

      const previousValue = queryClient.getQueryData(["Questionaire"]);

      queryClient.setQueryData(["Questionaire"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Questionaire"], previousValue),
    // After success or failure, refetch the Questionaires query
    onSettled: () => {
      queryClient.invalidateQueries(["Questionaire"]);
    },
  });
}

function useQuestionaireSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteQuestionaire(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Questionaire"]);

      const previousValue = queryClient.getQueryData(["Questionaire"]) || [];
      queryClient.setQueryData(["Questionaire"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Questionaire"], previousValue),
    // After success or failure, refetch the Questionaires query
    onSettled: () => {
      queryClient.invalidateQueries(["Questionaire"]);
    },
  });
}

function useQuestionaireMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleQuestionaire(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Questionaire"]);

      const previousValue = queryClient.getQueryData(["Questionaire"]) || [];
      queryClient.setQueryData(["Questionaire"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Questionaire"], previousValue),
    // After success or failure, refetch the Questionaires query
    onSettled: () => {
      queryClient.invalidateQueries(["Questionaire"]);
    },
  });
}

function useQuestionaireDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteQuestionaire(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Questionaire"]);

      const previousValue = queryClient.getQueryData(["Questionaire"]) || [];
      queryClient.setQueryData(["Questionaire"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Questionaire"], previousValue),
    // After success or failure, refetch the Questionaires query
    onSettled: () => {
      queryClient.invalidateQueries(["Questionaire"]);
    },
  });
}

function useQuestionaireCount() {
  return useQuery(["QuestionaireCount"], () => {
    return getQuestionaireCount();
  });
}

function useQuestionaireAggregate(record) {
  return useQuery("Questionaire", () => {
    return getQuestionaireAggregate(record);
  });
}

function useQuestionaireGetById(id) {
  return useQuery(["Questionaire", id], () => {
    return getQuestionaireById(id);
  });
}

export {
  useQuestionaireList,
  useQuestionaireCreate,
  useQuestionaireUpdate,
  useQuestionaireDelete,
  useQuestionaireMultipleSoftDelete,
  useQuestionaireCount,
  useQuestionaireSoftDelete,
  useQuestionaireAggregate,
  useQuestionaireGetById,
};
