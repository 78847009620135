import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listAgentsLadger,
  createAgentLadger,
  updateAgentLadger,
  softDeleteAgentLadger,
  softDeleteMultipleAgentLadger,
  deleteAgentLadger,
  getAgentLadgerAggregate,
  getAgentLadgerById,
  getAgentLadgerCount
} from "../services/AgentLadger.service";

function useAgentLadgerList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["AgentLadger", { page, limit, $and, sort }], () => listAgentsLadger(args));
}

function useAgentLadgerCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createAgentLadger(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["AgentLadger"]);

      const previousValue = queryClient.getQueryData(["AgentLadger"]) || [];

      queryClient.setQueryData(["AgentLadger"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AgentLadger"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["AgentLadger"]);
    },
  });
}

function useAgentLadgerUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateAgentLadger(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["AgentLadger"]);

      const previousValue = queryClient.getQueryData(["AgentLadger"]);

      queryClient.setQueryData(["AgentLadger"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AgentLadger"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["AgentLadger"]);
    },
  });
}

function useAgentLadgerSoftDelete() {
  console.log("useAgentLadgerSoftDelete")
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteAgentLadger(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AgentLadger"]);

      const previousValue = queryClient.getQueryData(["AgentLadger"]) || [];
      queryClient.setQueryData(["AgentLadger"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AgentLadger"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["AgentLadger"]);
    },
  });
}

function useAgentLadgerMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleAgentLadger(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AgentLadger"]);

      const previousValue = queryClient.getQueryData(["AgentLadger"]) || [];
      queryClient.setQueryData(["AgentLadger"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AgentLadger"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["AgentLadger"]);
    },
  });
}

function useAgentLadgerDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteAgentLadger(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AgentLadger"]);

      const previousValue = queryClient.getQueryData(["AgentLadger"]) || [];
      queryClient.setQueryData(["AgentLadger"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AgentLadger"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["AgentLadger"]);
    },
  });
}

function useAgentLadgerCount() {
  return useQuery(["AgentCount"], () => {
    return getAgentLadgerCount();
  });
}

function useAgentLadgerAggregate(record) {
  return useQuery("AgentLadger", () => {
    return getAgentLadgerAggregate(record);
  });
}

function useAgentLadgerGetById(id) {
  return useQuery(["AgentLadger", id], () => {
    return getAgentLadgerById(id);
  });
}

// function useAgentLadgerSuspend() {
//   const queryClient = useQueryClient();
//   return useMutation((record) => suspendAgent(record), {
//     onMutate: async (updatedData) => {
//       await queryClient.cancelQueries(["AgentLadger"]);

//       const previousValue = queryClient.getQueryData(["AgentLadger"]);

//       queryClient.setQueryData(["AgentLadger"], (old) => {
//         return old?.map((oldData) => {
//           if (oldData.id === updatedData.id) return updatedData;
//           else return oldData;
//         });
//       });
//       return previousValue;
//     },

//     // On failure, roll back to the previous value
//     onError: (err, variables, previousValue) =>
//       queryClient.setQueryData(["AgentLadger"], previousValue),
//     // After success or failure, refetch the Users query
//     onSettled: () => {
//       queryClient.invalidateQueries(["AgentLadger"]);
//     },
//   });
// }

export {
  useAgentLadgerList,
  useAgentLadgerCreate,
  useAgentLadgerUpdate,
  useAgentLadgerDelete,
  useAgentLadgerMultipleSoftDelete,
  useAgentLadgerCount,
  useAgentLadgerSoftDelete,
  useAgentLadgerAggregate,
  useAgentLadgerGetById
};
