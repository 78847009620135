import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listCommittees,
  createCommittee,
  updateCommittee,
  softDeleteCommittee,
  softDeleteMultipleCommittee,
  deleteCommittee,
  getCommitteeAggregate,
  getCommitteeById,
  getCommitteeCount,
} from "../services/Committee.service";

function useCommitteeList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Committee", { page, limit, $and, sort }], () =>
    listCommittees(args)
  );
}

function useCommitteeCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createCommittee(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Committee"]);

      const previousValue = queryClient.getQueryData(["Committee"]) || [];

      queryClient.setQueryData(["Committee"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Committee"], previousValue),
    // After success or failure, refetch the Committees query
    onSettled: () => {
      queryClient.invalidateQueries(["Committee"]);
    },
  });
}

function useCommitteeUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateCommittee(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Committee"]);

      const previousValue = queryClient.getQueryData(["Committee"]);

      queryClient.setQueryData(["Committee"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Committee"], previousValue),
    // After success or failure, refetch the Committees query
    onSettled: () => {
      queryClient.invalidateQueries(["Committee"]);
    },
  });
}

function useCommitteeSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteCommittee(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Committee"]);

      const previousValue = queryClient.getQueryData(["Committee"]) || [];
      queryClient.setQueryData(["Committee"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Committee"], previousValue),
    // After success or failure, refetch the Committees query
    onSettled: () => {
      queryClient.invalidateQueries(["Committee"]);
    },
  });
}

function useCommitteeMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleCommittee(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Committee"]);

      const previousValue = queryClient.getQueryData(["Committee"]) || [];
      queryClient.setQueryData(["Committee"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Committee"], previousValue),
    // After success or failure, refetch the Committees query
    onSettled: () => {
      queryClient.invalidateQueries(["Committee"]);
    },
  });
}

function useCommitteeDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteCommittee(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Committee"]);

      const previousValue = queryClient.getQueryData(["Committee"]) || [];
      queryClient.setQueryData(["Committee"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Committee"], previousValue),
    // After success or failure, refetch the Committees query
    onSettled: () => {
      queryClient.invalidateQueries(["Committee"]);
    },
  });
}

function useCommitteeCount() {
  return useQuery(["CommitteeCount"], () => {
    return getCommitteeCount();
  });
}

function useCommitteeAggregate(record) {
  return useQuery("Committee", () => {
    return getCommitteeAggregate(record);
  });
}

function useCommitteeGetById(id) {
  return useQuery(["Committee", id], () => {
    return getCommitteeById(id);
  });
}

export {
  useCommitteeList,
  useCommitteeCreate,
  useCommitteeUpdate,
  useCommitteeDelete,
  useCommitteeMultipleSoftDelete,
  useCommitteeCount,
  useCommitteeSoftDelete,
  useCommitteeAggregate,
  useCommitteeGetById,
};
