
    import React from 'react';
    import { useProjectConfigCreate, useProjectConfigSoftDelete,useProjectConfigMultipleSoftDelete, useProjectConfigUpdate } from '../../queries/ProjectConfig.queries';
    import ProjectConfig from './ProjectConfig';
    
    const ProjectConfigContainer = () => {
    
      const {mutate:addRecord} = useProjectConfigCreate();
      const {mutate:editRecord} = useProjectConfigUpdate();
      const {mutate:deleteRecord} = useProjectConfigSoftDelete();
      const {mutate:deleteRecords} = useProjectConfigMultipleSoftDelete();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <ProjectConfig 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
        />
      )
    }
    
    export default ProjectConfigContainer;
    