import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listHandlers,
  createHandler,
  updateHandler,
  softDeleteHandler,
  softDeleteMultipleHandler,
  deleteHandler,
  getHandlerAggregate,
  getHandlerById,
  getHandlerCount,
  importMultipleHandler,
  setHandlerActivateById,
  setHandlerAllocateById,
  importMultipleHandlerWithUser
} from "../services/Handler.service";

function useHandlerList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  console.log("args",args)
  return useQuery(["Handler", { page, limit, $and, sort }], () =>
    listHandlers(args)
  );
}

function useHandlerCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createHandler(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];

      queryClient.setQueryData(["Handler"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateHandler(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]);

      queryClient.setQueryData(["Handler"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteHandler(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleHandler(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}


//Import Handler


function useHandlerMultipleImport() {
  const queryClient = useQueryClient();
  return useMutation((record) => importMultipleHandler(record), {
    onMutate: async (importRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => !importRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerWithUser() {
  const queryClient = useQueryClient();
  return useMutation((record) => importMultipleHandlerWithUser(record), {
    onMutate: async (importRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => !importRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteHandler(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
}

function useHandlerCount() {
  return useQuery(["HandlerCount"], () => {
    return getHandlerCount();
  });
}

function useHandlerAggregate(record) {
  return useQuery("Handler", () => {
    return getHandlerAggregate(record);
  });
}

function useHandlerGetById(id) {
  return useQuery(["Handler", id], () => {
    return getHandlerById(id);
  });
}

function useHandlerActivate(){
  const queryClient = useQueryClient();
  return useMutation((record) => setHandlerActivateById(record), {
    onMutate: async (activeRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => record.id !== activeRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
  // return useQuery(["Handler", id], () => {
  //   return setHandlerActivateById(id);
  // });
}
function useHandlerAllocate(){
  console.log("useHandlerAllocate")
  const queryClient = useQueryClient();
  return useMutation((record) => setHandlerAllocateById(record), {
    onMutate: async (activeRecord) => {
      await queryClient.cancelQueries(["Handler"]);

      const previousValue = queryClient.getQueryData(["Handler"]) || [];
      queryClient.setQueryData(["Handler"], (oldData) =>
        previousValue.filter((record) => record.id !== activeRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Handler"], previousValue),
    // After success or failure, refetch the Handlers query
    onSettled: () => {
      queryClient.invalidateQueries(["Handler"]);
    },
  });
  // return useQuery(["Handler", id], () => {
  //   return setHandlerActivateById(id);
  // });
}

export {
  useHandlerList,
  useHandlerCreate,
  useHandlerUpdate,
  useHandlerDelete,
  useHandlerMultipleSoftDelete,
  useHandlerCount,
  useHandlerSoftDelete,
  useHandlerAggregate,
  useHandlerGetById,
  useHandlerMultipleImport,
  useHandlerActivate,
  useHandlerAllocate,
  useHandlerWithUser
};
