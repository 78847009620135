import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { committee } = API_URLS;

export const listCommittees = (payload) => {
  return apiClient({ url: committee.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createCommittee = (payload) => {
  return apiClient({ url: committee.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateCommittee = (payload) => {
  return apiClient({
    url: committee.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteCommittee = (payload) => {
  return apiClient({
    url: committee.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCommittee = (payload) => {
  return apiClient({
    url: committee.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCommitteeCount = () => {
  return apiClient({ url: committee.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCommitteeAggregate = (payload) => {
  return apiClient({ url: committee.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCommitteeById = (payload) => {
  return apiClient({
    url: committee.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleCommittee = (payload) => {
  return apiClient({
    url: committee.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
