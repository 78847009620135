import { apiClient } from "../api/client";
import { API_URLS } from "../api/config";
const { PostReporting } = API_URLS;

export const listPostReportings = (payload) => {
  console.log("PostReporting.list",PostReporting.list)
  return apiClient({ url: PostReporting.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createPostReporting = (payload) => {
  payload.isSubPostReporting = false
  payload.isKYCDocumentUploaded = true
  payload.isKYC = true
  return apiClient({ url: PostReporting.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updatePostReporting = (payload) => {
  return apiClient({
    url: PostReporting.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeletePostReporting = (payload) => {
  return apiClient({
    url: PostReporting.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deletePostReporting = (payload) => {
  return apiClient({
    url: PostReporting.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostReportingCount = () => {
  return apiClient({ url: PostReporting.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostReportingAggregate = (payload) => {
  return apiClient({ url: PostReporting.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostReportingById = (payload) => {  
  return apiClient({
    url: PostReporting.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultiplePostReporting = (payload) => {
  return apiClient({
    url: PostReporting.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const suspendPostReporting = (payload) => {
  console.log("payload.id",payload.id)
  return apiClient({
    url: PostReporting.suspend_unSuspend,
    data: {userId:payload.id},
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't suspend/unsuspend record.");
    });
};

export const changePostReportingKYCStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: PostReporting.kycApprove + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeKYCDocumentStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: PostReporting.uploadKycDoc + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};