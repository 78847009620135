
    import React from 'react';
    import { useAgentLadgerCreate, useAgentLadgerSoftDelete,useAgentLadgerMultipleSoftDelete, useAgentLadgerUpdate } from '../../queries/AgentLadger.queries';
    import AgentLadger from './AgentLadger';
    
    const AgentLadgerContainer = () => {
    
      const {mutate:addRecord} = useAgentLadgerCreate();
      const {mutate:editRecord} = useAgentLadgerUpdate();
      const {mutate:deleteRecord} = useAgentLadgerSoftDelete();
      const {mutate:deleteRecords} = useAgentLadgerMultipleSoftDelete();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <AgentLadger 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
        />
      )
    }
    
    export default AgentLadgerContainer;
    