import React from "react";

export const Suspend = ({ cell, isValid }) => {
  return isValid ? (
    <div className={cell?.value ? "clickApprove" : "clickTag"}>
      {cell?.value  ? "Suspended " : "Suspend"}
    </div>
  ) : (
    <div className="clickDisabled">
      Suspended
    </div>
  );
};

export const Approved = ({ cell }) => {
  const isApproved = cell?.value == true ? true : false;
  return isApproved ? (
    <div className="clickApproved">
      Approved
    </div>
  ) : (
    <div className={cell?.isApproved ? "clickApproved" : "clickApprove"}>
      Approve
    </div>
  );
};

export const BlueTickVerified = ({ cell }) => {
  const isApproved = cell?.value == true ? true : false;
  console.log("cell",cell?.row?.original?.type);
  return isApproved ? (
    <div className="clickApproved">
      BlueTickVerified
    </div>
  ) : (
    <div className={cell?.isBlueTickVerified ? "clickApproved" : cell?.row?.original?.type==3 ?"clickApproved":"clickApprove"}>
      BlueTickVerify
    </div>
  );
};

export const TrueFalse = ({ cell }) => {
  const isApproved = cell?.value == true ? true : false;
  return isApproved ? (
    <div className="clickApproved">
      True
    </div>
  ) : (
    <div className={cell?.isApproved ? "clickApproved" : "clickApprove"}>
      False
    </div>
  );
};
