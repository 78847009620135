import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { posts } = API_URLS;

export const listPostss = (payload) => {
  delete payload.query.isActive
  delete payload.query.isDeleted
  return apiClient({ url: posts.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createPosts = (payload) => {
  return apiClient({ url: posts.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updatePosts = (payload) => {
  return apiClient({
    url: posts.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeletePosts = (payload) => {
  return apiClient({
    url: posts.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deletePosts = (payload) => {
  return apiClient({
    url: posts.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostsCount = () => {
  return apiClient({ url: posts.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostsAggregate = (payload) => {
  return apiClient({ url: posts.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPostsById = (payload) => {
  return apiClient({
    url: posts.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultiplePosts = (payload) => {
  return apiClient({
    url: posts.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
