import React from "react";
import { Popup } from "./index";
import Select from "react-select";
import {
  updateComplaint,
} from "../../services/Complaint.service";

const STATUS = [{
  key: "RESOLVED",
  value: 1,
}, {
  key: "PENDING",
  value: 2,
}, {
  key: "DISPUTED",
  value: 3,
  isDisabled:true
}]

export const DisputeComplaintPopUp = ({
  onStatusChange,
  isOpen,
  handleClose,
  popupTitle,
  currentRecord,
}) => {
  const [complaintStatus, setComplaintStatus] = React.useState(undefined);

  React.useEffect(() => {
    if (isOpen) {
      const currentStatus=STATUS.filter(x => {
        return x.value.toString() === currentRecord.status;
      })[0];
      setComplaintStatus(currentStatus);
    }
  }, [isOpen]);

  const onSubmit = () => {
    //updateComplaint({ id:currentRecord.id,status:complaintStatus.value });
    onStatusChange({ id:currentRecord.id,status:complaintStatus.value })
    handleClose()
  };

  return (
    <Popup
      handleCancel={() => handleClose()}
      handleSubmit={onSubmit}
      isOpen={isOpen}
      popupbody="tagPopup"
      popupTitle={popupTitle}
      children={
        <div className="p-3">
          <div className="d-flex justify-between">
            <div className="flex-grow-1">
              <Select
                options={STATUS}
                value={complaintStatus}
                onChange={setComplaintStatus}
                getOptionLabel={(option) => option.key}
                getOptionValue={(option) => option.value}
              />
            </div>
            <button
              className="btn btn-primary flex-shrink-0 ml-2"
              onClick={onSubmit}
            >
              Change Status
            </button>
          </div>

        </div>
      }
    ></Popup>
  );
};
