import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listPostss,
  createPosts,
  updatePosts,
  softDeletePosts,
  softDeleteMultiplePosts,
  deletePosts,
  getPostsAggregate,
  getPostsById,
  getPostsCount,
} from "../services/Posts.service";

function usePostsList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Posts", { page, limit, $and, sort }], () =>
    listPostss(args)
  );
}

function usePostsCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createPosts(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Posts"]);

      const previousValue = queryClient.getQueryData(["Posts"]) || [];

      queryClient.setQueryData(["Posts"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Posts"], previousValue),
    // After success or failure, refetch the Postss query
    onSettled: () => {
      queryClient.invalidateQueries(["Posts"]);
    },
  });
}

function usePostsUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updatePosts(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Posts"]);

      const previousValue = queryClient.getQueryData(["Posts"]);

      queryClient.setQueryData(["Posts"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Posts"], previousValue),
    // After success or failure, refetch the Postss query
    onSettled: () => {
      queryClient.invalidateQueries(["Posts"]);
    },
  });
}

function usePostsSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeletePosts(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Posts"]);

      const previousValue = queryClient.getQueryData(["Posts"]) || [];
      queryClient.setQueryData(["Posts"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Posts"], previousValue),
    // After success or failure, refetch the Postss query
    onSettled: () => {
      queryClient.invalidateQueries(["Posts"]);
    },
  });
}

function usePostsMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultiplePosts(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Posts"]);

      const previousValue = queryClient.getQueryData(["Posts"]) || [];
      queryClient.setQueryData(["Posts"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Posts"], previousValue),
    // After success or failure, refetch the Postss query
    onSettled: () => {
      queryClient.invalidateQueries(["Posts"]);
    },
  });
}

function usePostsDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deletePosts(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Posts"]);

      const previousValue = queryClient.getQueryData(["Posts"]) || [];
      queryClient.setQueryData(["Posts"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Posts"], previousValue),
    // After success or failure, refetch the Postss query
    onSettled: () => {
      queryClient.invalidateQueries(["Posts"]);
    },
  });
}

function usePostsCount() {
  return useQuery(["PostsCount"], () => {
    return getPostsCount();
  });
}

function usePostsAggregate(record) {
  return useQuery("Posts", () => {
    return getPostsAggregate(record);
  });
}

function usePostsGetById(id) {
  return useQuery(["Posts", id], () => {
    return getPostsById(id);
  });
}

export {
  usePostsList,
  usePostsCreate,
  usePostsUpdate,
  usePostsDelete,
  usePostsMultipleSoftDelete,
  usePostsCount,
  usePostsSoftDelete,
  usePostsAggregate,
  usePostsGetById,
};
