import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const StateReducer = createSlice({
  name: "State",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("StateReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = StateReducer;
export const { defaultAction } = actions;
export default reducer;
