import React from "react";
import Drawer from "rc-drawer";
import ThemedForm from "@dhiwise/core-ui";
import Button from "../Button";

import { API_URLS, UPLOAD_URL } from "../../../api/config";
import { apiClient } from "../../../api/client";
import { handleUpload, loadOptions } from "../../../api/general";

import Modal from "react-modal";

export const SummaryListView = ({
  onClose,
  isOpen,
  handleCancel,
  currentData,
}) => {
  const formRef = React.createRef();

  const themeMode =
    localStorage.getItem("layout_version") === "dark-only" ? "dark" : "light";

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className={currentData ? "customPopup modal-white-bg" : "customConfirmPopup"}
      overlayClassName="popupWrap"
    >
      {currentData ?
        <div className="relative">
          <div className="p-4 text-center">
            <h3 className="danger-color">List of error data</h3>
            <p className="mb-0">
              <table className="modal-tr-td">
                <tr className="p-4">
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>ORG Name</th>
                  <th>Phone</th>
                  <th>Handler URL</th>
                  <th>Error</th>
                </tr>
                {currentData.errorData && currentData.errorData.map((item, i) =>
                  <tr className="p-4">
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.handlerURL}</td>
                    <td class="danger-color">{item.error}</td>
                  </tr>
                )}
              </table>
            </p>
          </div>
          {(handleCancel) && (
            <div className="py-3 px-4 drawerFooter">
              {handleCancel && (
                <button
                  className="btn btn-light"
                  onClick={handleCancel}
                  type="button"
                >
                  Cancel
                </button>
              )}
            </div>
          )}
        </div>
        :
        <div className="relative">
          <div className="p-4 text-center">
            <h3 className="danger-color">Please Wait ...</h3>
            {/* <div>
              <div className="loader">
                <div className="whirly-loader"> </div>
              </div>
            </div> */}
            <p className="mb-0">
              Data Processing
            </p>
          </div>

        </div>
      }
    </Modal>
  );
};
