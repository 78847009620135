
    import React from "react";
    import { generateQueryFromFilters,getSchemaOptions } from "../../api/general";
    import Loader from "../../components/common/Loader";
    import SkeletonLoader from "../../components/common/SkeletonLoader";
    import SchemaTable from "../../components/Table/SchemaTable";
    import { useHandlerList } from "../../queries/Handler.queries";
    import { useUserList } from "../../queries/User.queries";


    import ModelJsonSchema from "./Handler.schema.json";

    const tableSchema = ModelJsonSchema.actions.find(
      (action) => action.category === "listing"
    );

    const HandlerTable = ({
      onBlockToggle = () => {},
      onApprove = () =>{},
      mockData = [],
      onAdd,
      onEdit,
      onView,
      onDelete,
      onMultiDelete,
      onAssignRole,
      onCustomActionClick = () => {},
      noAdd,
      noEdit,
      noDelete,
      noView,
      onImport,
      onImportWithUser,
      onVarifyData,
      queryOptions = {},
      onUpdateVarifyRecord,
      noVarifyData
    }) => {
      const [page, setPage] = React.useState(1);
      const [limit, setLimit] = React.useState(
        ModelJsonSchema?.screenLayout?.listing?.config?.limitSetup || 10
      );
      const [filters, setFilters] = React.useState([]);

      const [tableState, setTableState] = React.useState({});
      const [queryData, setQueryData] = React.useState(() => ({
        query: { isDeleted: false },
        options: {
          ...getSchemaOptions(tableSchema,tableState),

          },
      }));

      React.useEffect(() => {
        setQueryData({
          options: {
            ...getSchemaOptions(tableSchema,tableState?.sortBy||[]),
          },
        })
      }, [tableState?.sortBy]);

      const { error, data, isLoading } = useHandlerList({
        query: { isDeleted: false, ...queryData.query, ...queryOptions },
        options: { page, limit, ...queryData.options } ,
      });

      const applyFilters = (data) => {
        setQueryData({
          query: {
            ...generateQueryFromFilters(data?.filters),
            
            isDeleted: false,
            },
          options: {
            ...getSchemaOptions(tableSchema),

            }
        });
        setFilters(data?.filters?.map((filter, index) => ({ id: index, ...filter  })) || []);
      };

      const onPageChange = React.useCallback((pageIndex) => {
        pageIndex >= 0 && setPage(pageIndex + 1);
      }, []);

      const onLimitChange = React.useCallback((pageLimit) => {
        setLimit(pageLimit);
      }, []);

      if (isLoading)
        return ModelJsonSchema?.screenLayout?.loader === "lazyLoading" ? (
          <SkeletonLoader />
        ) : (
          <Loader />
        );

      if (error) {
        console.log("error => ", error);
      }

      return (

        <SchemaTable
        onBlockToggle={onBlockToggle}
        applyFilters={applyFilters}
        onApprove={onApprove}
        appliedFilters={filters}
        setFilter={setTableState}
        initialState={tableState}
        data={data}
        mockData={mockData}
        schema={ModelJsonSchema}
        onImport = {onImport}
        onImportWithUser = {onImportWithUser}
        onEdit={onEdit}
        onDelete={onDelete}
        onMultiDelete={onMultiDelete}
        onAssignRole={onAssignRole}
        onView={onView}
        onCustomActionClick={onCustomActionClick}
        onUpdateVarifyRecord={onUpdateVarifyRecord}

        noAdd={noAdd}
        noEdit={noEdit}
        noDelete={noDelete}
        noView={noView}
        noVarifyData={noVarifyData}

        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
      );
    };

    export default HandlerTable;
