
    import React from 'react';
    import { useComplaintCreate, useComplaintSoftDelete,useComplaintMultipleSoftDelete, useComplaintUpdate } from '../../queries/Complaint.queries';
    import Complaint from './Complaint';
    
    const ComplaintContainer = () => {
    
      const {mutate:addRecord} = useComplaintCreate();
      const {mutate:editRecord} = useComplaintUpdate();
      const {mutate:deleteRecord} = useComplaintSoftDelete();
      const {mutate:deleteRecords} = useComplaintMultipleSoftDelete();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <Complaint 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
        />
      )
    }
    
    export default ComplaintContainer;
    