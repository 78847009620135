import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listAreas,
  createArea,
  updateArea,
  softDeleteArea,
  softDeleteMultipleArea,
  deleteArea,
  getAreaAggregate,
  getAreaById,
  getAreaCount,
} from "../services/Area.service";

function useAreaList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Area", { page, limit, $and, sort }], () => listAreas(args));
}

function useAreaCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createArea(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Area"]);

      const previousValue = queryClient.getQueryData(["Area"]) || [];

      queryClient.setQueryData(["Area"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Area"], previousValue),
    // After success or failure, refetch the Areas query
    onSettled: () => {
      queryClient.invalidateQueries(["Area"]);
    },
  });
}

function useAreaUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateArea(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Area"]);

      const previousValue = queryClient.getQueryData(["Area"]);

      queryClient.setQueryData(["Area"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Area"], previousValue),
    // After success or failure, refetch the Areas query
    onSettled: () => {
      queryClient.invalidateQueries(["Area"]);
    },
  });
}

function useAreaSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteArea(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Area"]);

      const previousValue = queryClient.getQueryData(["Area"]) || [];
      queryClient.setQueryData(["Area"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Area"], previousValue),
    // After success or failure, refetch the Areas query
    onSettled: () => {
      queryClient.invalidateQueries(["Area"]);
    },
  });
}

function useAreaMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleArea(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Area"]);

      const previousValue = queryClient.getQueryData(["Area"]) || [];
      queryClient.setQueryData(["Area"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Area"], previousValue),
    // After success or failure, refetch the Areas query
    onSettled: () => {
      queryClient.invalidateQueries(["Area"]);
    },
  });
}

function useAreaDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteArea(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Area"]);

      const previousValue = queryClient.getQueryData(["Area"]) || [];
      queryClient.setQueryData(["Area"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Area"], previousValue),
    // After success or failure, refetch the Areas query
    onSettled: () => {
      queryClient.invalidateQueries(["Area"]);
    },
  });
}

function useAreaCount() {
  return useQuery(["AreaCount"], () => {
    return getAreaCount();
  });
}

function useAreaAggregate(record) {
  return useQuery("Area", () => {
    return getAreaAggregate(record);
  });
}

function useAreaGetById(id) {
  return useQuery(["Area", id], () => {
    return getAreaById(id);
  });
}

export {
  useAreaList,
  useAreaCreate,
  useAreaUpdate,
  useAreaDelete,
  useAreaMultipleSoftDelete,
  useAreaCount,
  useAreaSoftDelete,
  useAreaAggregate,
  useAreaGetById,
};
