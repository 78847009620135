import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listReportReasons,
  createReportReason,
  updateReportReason,
  softDeleteReportReason,
  softDeleteMultipleReportReason,
  deleteReportReason,
  getReportReasonAggregate,
  getReportReasonById,
  getReportReasonCount,
  suspendReportReason,
  changeReportReasonKYCStatus,
  changeKYCDocumentStatus
} from "../services/ReportReason.service";

function useReportReasonList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["ReportReason", { page, limit, $and, sort }], () => listReportReasons(args));
}

function useReportReasonCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createReportReason(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];

      queryClient.setQueryData(["ReportReason"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useReportReasonUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateReportReason(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]);

      queryClient.setQueryData(["ReportReason"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useReportReasonSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteReportReason(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];
      queryClient.setQueryData(["ReportReason"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useReportReasonMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleReportReason(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];
      queryClient.setQueryData(["ReportReason"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useReportReasonDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteReportReason(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];
      queryClient.setQueryData(["ReportReason"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useReportReasonCount() {
  return useQuery(["ReportReasonCount"], () => {
    return getReportReasonCount();
  });
}

function useReportReasonAggregate(record) {
  return useQuery("ReportReason", () => {
    return getReportReasonAggregate(record);
  });
}

function useReportReasonGetById(id) {
  return useQuery(["ReportReason", id], () => {
    return getReportReasonById(id);
  });
}

function useReportReasonSuspend() {
  const queryClient = useQueryClient();
  return useMutation((record) => suspendReportReason(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]);

      queryClient.setQueryData(["ReportReason"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the Users query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useApproveReportReasonKYC() {
  const queryClient = useQueryClient();
  return useMutation((record) => changeReportReasonKYCStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];
      queryClient.setQueryData(["ReportReason"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}

function useUpdateKYCDocument() {
  console.log("useUpdateKYCDocument")
  const queryClient = useQueryClient();
  return useMutation((record) => changeKYCDocumentStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ReportReason"]);

      const previousValue = queryClient.getQueryData(["ReportReason"]) || [];
      queryClient.setQueryData(["ReportReason"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ReportReason"], previousValue),
    // After success or failure, refetch the ReportReasons query
    onSettled: () => {
      queryClient.invalidateQueries(["ReportReason"]);
    },
  });
}


export {
  useReportReasonList,
  useReportReasonCreate,
  useReportReasonUpdate,
  useReportReasonDelete,
  useReportReasonMultipleSoftDelete,
  useReportReasonCount,
  useReportReasonSoftDelete,
  useReportReasonAggregate,
  useReportReasonGetById,
  useReportReasonSuspend,
  useApproveReportReasonKYC,
  useUpdateKYCDocument
};
