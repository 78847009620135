
import { Button } from "reactstrap";
import React, { useState } from "react";

import { apiClient } from "../../api/client";
import { API_URLS } from '../../api/config';
import { formatLabel } from "../../utils/utils";
import Loader from "../../components/common/Loader";
import schemaJson from './Configuration.schema.json';
import { successToast } from "../../utils/notifications";

const { configuration } = API_URLS;

//const handlerData = [];

const Configuration = () => {

	React.useEffect(() => {
		onGetRecord()
	}, []);

	const [mainHandlerPrice, setMainHandlerPrice] = useState(0);
	const [subHandlerPrice, setSubHandlerPrice] = useState(0);
	const [rssFeedStatus, setRssFeedStatus] = useState("false");
	// const [handlerData, setHandlerData] = useState(0);

	const onGetRecord = () => {

		console.log("onGetRecord");
		console.log("configuration.get: " + configuration.get);

		return apiClient({
			url: configuration.get,
			method: 'GET'
		})
			.then((res) => {
				//handlerData =res.data.handlerPrice
				console.log("reee",res)
				setMainHandlerPrice(res.data.handlerPrice.mainHandler)
				setSubHandlerPrice(res.data.handlerPrice.subHandler)
				setRssFeedStatus(res.data.rssFeedStatus)
				//setHandlerData(res.data.handlerPrice)
				
			})
			.catch((err) => {
				console.log("err: " + JSON.stringify(err));
				throw new Error(err?.data?.message);

			});
	}

	const onEditRecord = () => {
		console.log('main Handler price', mainHandlerPrice)
		console.log('sub Handler price', subHandlerPrice)

		let updatedState = {
			"handlerPrice": {
				"mainHandler": Number(mainHandlerPrice),
				"subHandler": Number(subHandlerPrice)
			},
			"rssFeedStatus":rssFeedStatus
		}

		console.log('updatedState', updatedState);

		return apiClient({
			url: configuration.create,
			data: updatedState
		})
			.then((res) => {
				console.log("res.data: " + JSON.stringify(res.data));
				successToast("Your request is successfully executed")
			})
			.catch((err) => {
				throw new Error(err?.data?.message);
			});
	}

	const modelName = formatLabel(schemaJson?.name);

	return (
		<form className="py-3 px-4 drawerBody">

			<div className="py-3 px-4 drawerBody">
				<h5>
					View {modelName} Record
				</h5>


				<div className="row">

					<div className="col-md-4">
						<div className="form-group">
							<label for="mainHandler" style={{ float: 'left', marginRight: "5px" }}>
								Main Handler
							</label>
							<div>
								<input
									type='number'
									id="mainHandler"
									name="mainHandler"
									value={mainHandlerPrice}
									onChange={(text) => {
										setMainHandlerPrice(text.target.value)
									}}
								/>
							</div>
						</div>
					</div>

					<div className="col-md-4">
						<div className="form-group">
							<label for="subHandler" style={{ float: 'left', marginRight: "5px" }}>
								Sub Handler
							</label>
							<div>
								<input
									type='number'
									id="subHandler"
									name="subHandler"
									value={subHandlerPrice}
									onChange={(text) => {
										setSubHandlerPrice(text.target.value)
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				
				<div className="row">

					<div className="col-md-4">
						<div className="form-group">
							<label for="mainHandler">
								Rss Feed Status
								<input type='checkbox'
									value={rssFeedStatus}
									checked={rssFeedStatus}
									onChange={(text) => {
										if(text.target.value == 'true'){
											text.target.value = "false"
										}else{
											text.target.value = "true"
										}
										setRssFeedStatus(text.target.value)
									}}
								/>
							</label>
							
						</div>
					</div>

					{/* <div className="col-md-4">
						<div className="form-group">
							<label for="subHandler" style={{ float: 'left', marginRight: "5px" }}>
								Sub Handler
							</label>
							<div>
								<input
									type='number'
									id="subHandler"
									name="subHandler"
									value={subHandlerPrice}
									onChange={(text) => {
										setSubHandlerPrice(text.target.value)
									}}
								/>
							</div>
						</div>
					</div> */}
				</div>

				<div className="py-3 px-1  flex-shrink-0">
					<button className="btn btn-light" type="button">
						Cancel
					</button>
					<Button className="ml-2" onClick={onEditRecord} type="button">
						Submit
					</Button>
				</div>

			</div>
		</form>
	);
};


export default Configuration;