import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listInquirys,
  createInquiry,
  updateInquiry,
  softDeleteInquiry,
  softDeleteMultipleInquiry,
  deleteInquiry,
  getInquiryAggregate,
  getInquiryById,
  getInquiryCount,
} from "../services/Inquiry.service";

function useInquiryList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Inquiry", { page, limit, $and, sort }], () => listInquirys(args));
}

function useInquiryCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createInquiry(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Inquiry"]);

      const previousValue = queryClient.getQueryData(["Inquiry"]) || [];

      queryClient.setQueryData(["Inquiry"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Inquiry"], previousValue),
    // After success or failure, refetch the Inquirys query
    onSettled: () => {
      queryClient.invalidateQueries(["Inquiry"]);
    },
  });
}

function useInquiryUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateInquiry(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Inquiry"]);

      const previousValue = queryClient.getQueryData(["Inquiry"]);

      queryClient.setQueryData(["Inquiry"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Inquiry"], previousValue),
    // After success or failure, refetch the Inquirys query
    onSettled: () => {
      queryClient.invalidateQueries(["Inquiry"]);
    },
  });
}

function useInquirySoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteInquiry(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Inquiry"]);

      const previousValue = queryClient.getQueryData(["Inquiry"]) || [];
      queryClient.setQueryData(["Inquiry"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Inquiry"], previousValue),
    // After success or failure, refetch the Inquirys query
    onSettled: () => {
      queryClient.invalidateQueries(["Inquiry"]);
    },
  });
}

function useInquiryMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleInquiry(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Inquiry"]);

      const previousValue = queryClient.getQueryData(["Inquiry"]) || [];
      queryClient.setQueryData(["Inquiry"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Inquiry"], previousValue),
    // After success or failure, refetch the Inquirys query
    onSettled: () => {
      queryClient.invalidateQueries(["Inquiry"]);
    },
  });
}

function useInquiryDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteInquiry(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Inquiry"]);

      const previousValue = queryClient.getQueryData(["Inquiry"]) || [];
      queryClient.setQueryData(["Inquiry"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Inquiry"], previousValue),
    // After success or failure, refetch the Inquirys query
    onSettled: () => {
      queryClient.invalidateQueries(["Inquiry"]);
    },
  });
}

function useInquiryCount() {
  return useQuery(["InquiryCount"], () => {
    return getInquiryCount();
  });
}

function useInquiryAggregate(record) {
  return useQuery("Inquiry", () => {
    return getInquiryAggregate(record);
  });
}

function useInquiryGetById(id) {
  return useQuery(["Inquiry", id], () => {
    return getInquiryById(id);
  });
}

export {
  useInquiryList,
  useInquiryCreate,
  useInquiryUpdate,
  useInquiryDelete,
  useInquiryMultipleSoftDelete,
  useInquiryCount,
  useInquirySoftDelete,
  useInquiryAggregate,
  useInquiryGetById,
};
