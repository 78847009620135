import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listRssFeed,
  createRssFeed,
  updateRssFeed,
  softDeleteRssFeed,
  softDeleteMultipleRssFeed,
  deleteRssFeed,
  getRssFeedAggregate,
  getRssFeedById,
  getRssFeedCount
} from "../services/RssFeed.service";

function useRssFeedList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["RssFeed", { page, limit, $and, sort }], () => listRssFeed(args));
}

function useRssFeedCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createRssFeed(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["RssFeed"]);

      const previousValue = queryClient.getQueryData(["RssFeed"]) || [];

      queryClient.setQueryData(["RssFeed"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["RssFeed"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["RssFeed"]);
    },
  });
}

function useRssFeedUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateRssFeed(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["RssFeed"]);

      const previousValue = queryClient.getQueryData(["RssFeed"]);

      queryClient.setQueryData(["RssFeed"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["RssFeed"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["RssFeed"]);
    },
  });
}

function useRssFeedSoftDelete() {
  console.log("useRssFeedSoftDelete")
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteRssFeed(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["RssFeed"]);

      const previousValue = queryClient.getQueryData(["RssFeed"]) || [];
      queryClient.setQueryData(["RssFeed"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["RssFeed"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["RssFeed"]);
    },
  });
}

function useRssFeedMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleRssFeed(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["RssFeed"]);

      const previousValue = queryClient.getQueryData(["RssFeed"]) || [];
      queryClient.setQueryData(["RssFeed"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["RssFeed"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["RssFeed"]);
    },
  });
}

function useRssFeedDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteRssFeed(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["RssFeed"]);

      const previousValue = queryClient.getQueryData(["RssFeed"]) || [];
      queryClient.setQueryData(["RssFeed"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["RssFeed"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["RssFeed"]);
    },
  });
}

function useRssFeedCount() {
  return useQuery(["AgentCount"], () => {
    return getRssFeedCount();
  });
}

function useRssFeedAggregate(record) {
  return useQuery("RssFeed", () => {
    return getRssFeedAggregate(record);
  });
}

function useRssFeedGetById(id) {
  return useQuery(["RssFeed", id], () => {
    return getRssFeedById(id);
  });
}

// function useRssFeedSuspend() {
//   const queryClient = useQueryClient();
//   return useMutation((record) => suspendAgent(record), {
//     onMutate: async (updatedData) => {
//       await queryClient.cancelQueries(["RssFeed"]);

//       const previousValue = queryClient.getQueryData(["RssFeed"]);

//       queryClient.setQueryData(["RssFeed"], (old) => {
//         return old?.map((oldData) => {
//           if (oldData.id === updatedData.id) return updatedData;
//           else return oldData;
//         });
//       });
//       return previousValue;
//     },

//     // On failure, roll back to the previous value
//     onError: (err, variables, previousValue) =>
//       queryClient.setQueryData(["RssFeed"], previousValue),
//     // After success or failure, refetch the Users query
//     onSettled: () => {
//       queryClient.invalidateQueries(["RssFeed"]);
//     },
//   });
// }

export {
  useRssFeedList,
  useRssFeedCreate,
  useRssFeedUpdate,
  useRssFeedDelete,
  useRssFeedMultipleSoftDelete,
  useRssFeedCount,
  useRssFeedSoftDelete,
  useRssFeedAggregate,
  useRssFeedGetById
};
