
    import React from 'react';
    import { useProfileReportingSuspend,useProfileReportingCreate, useProfileReportingSoftDelete,useProfileReportingMultipleSoftDelete, useProfileReportingUpdate,useApproveProfileReportingKYC,useUpdateKYCDocument  } from '../../queries/profileReporting.queries';
    import ProfileReporting from './ProfileReporting';
    
    const ProfileReportingContainer = () => {
    
      const {mutate:addRecord} = useProfileReportingCreate();
      const {mutate:editRecord} = useProfileReportingUpdate();
      const {mutate:deleteRecord} = useProfileReportingSoftDelete();
      const {mutate:deleteRecords} = useProfileReportingMultipleSoftDelete();
      const {mutate:reportedProfileSuspend} = useProfileReportingSuspend();
      const {mutate:approveKYC} = useApproveProfileReportingKYC();
      const {mutate:verifyKYCDocumentUpload} = useUpdateKYCDocument();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onProfileReportingSuspend = (record) => {
        return new Promise((resolve, reject) => {
          record.isBlocked = true
          reportedProfileSuspend(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onApproveKYC = (record) => {
        return new Promise((resolve, reject) => {
          approveKYC(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onKYCDocumentUpload = (record) => {
        return new Promise((resolve, reject) => {
          console.log("sgr")
          verifyKYCDocumentUpload(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <ProfileReporting 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
          reportedProfileSuspend={onProfileReportingSuspend}
          approveKYC={onApproveKYC}
          verifyKYCDocumentUpload ={onKYCDocumentUpload}
        />
      )
    }
    
    export default ProfileReportingContainer;
    