import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listProfileReportings,
  createProfileReporting,
  updateProfileReporting,
  softDeleteProfileReporting,
  softDeleteMultipleProfileReporting,
  deleteProfileReporting,
  getProfileReportingAggregate,
  getProfileReportingById,
  getProfileReportingCount,
  suspendProfileReporting,
  changeProfileReportingKYCStatus,
  changeKYCDocumentStatus
} from "../services/ProfileReporting.service";

function useProfileReportingList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["ProfileReporting", { page, limit, $and, sort }], () => listProfileReportings(args));
}

function useProfileReportingCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createProfileReporting(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];

      queryClient.setQueryData(["ProfileReporting"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useProfileReportingUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateProfileReporting(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]);

      queryClient.setQueryData(["ProfileReporting"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useProfileReportingSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteProfileReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];
      queryClient.setQueryData(["ProfileReporting"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useProfileReportingMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleProfileReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];
      queryClient.setQueryData(["ProfileReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useProfileReportingDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteProfileReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];
      queryClient.setQueryData(["ProfileReporting"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useProfileReportingCount() {
  return useQuery(["ProfileReportingCount"], () => {
    return getProfileReportingCount();
  });
}

function useProfileReportingAggregate(record) {
  return useQuery("ProfileReporting", () => {
    return getProfileReportingAggregate(record);
  });
}

function useProfileReportingGetById(id) {
  return useQuery(["ProfileReporting", id], () => {
    return getProfileReportingById(id);
  });
}

function useProfileReportingSuspend() {
  const queryClient = useQueryClient();
  return useMutation((record) => suspendProfileReporting(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]);

      queryClient.setQueryData(["ProfileReporting"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the Users query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useApproveProfileReportingKYC() {
  const queryClient = useQueryClient();
  return useMutation((record) => changeProfileReportingKYCStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];
      queryClient.setQueryData(["ProfileReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}

function useUpdateKYCDocument() {
  console.log("useUpdateKYCDocument")
  const queryClient = useQueryClient();
  return useMutation((record) => changeKYCDocumentStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProfileReporting"]);

      const previousValue = queryClient.getQueryData(["ProfileReporting"]) || [];
      queryClient.setQueryData(["ProfileReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProfileReporting"], previousValue),
    // After success or failure, refetch the ProfileReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["ProfileReporting"]);
    },
  });
}


export {
  useProfileReportingList,
  useProfileReportingCreate,
  useProfileReportingUpdate,
  useProfileReportingDelete,
  useProfileReportingMultipleSoftDelete,
  useProfileReportingCount,
  useProfileReportingSoftDelete,
  useProfileReportingAggregate,
  useProfileReportingGetById,
  useProfileReportingSuspend,
  useApproveProfileReportingKYC,
  useUpdateKYCDocument
};
