import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listUserActivitys,
  createUserActivity,
  updateUserActivity,
  softDeleteUserActivity,
  softDeleteMultipleUserActivity,
  deleteUserActivity,
  getUserActivityAggregate,
  getUserActivityById,
  getUserActivityCount,
} from "../services/UserActivity.service";

function useUserActivityList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["UserActivity", { page, limit, $and, sort }], () =>
    listUserActivitys(args)
  );
}

function useUserActivityCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createUserActivity(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["UserActivity"]);

      const previousValue = queryClient.getQueryData(["UserActivity"]) || [];

      queryClient.setQueryData(["UserActivity"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["UserActivity"], previousValue),
    // After success or failure, refetch the UserActivitys query
    onSettled: () => {
      queryClient.invalidateQueries(["UserActivity"]);
    },
  });
}

function useUserActivityUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateUserActivity(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["UserActivity"]);

      const previousValue = queryClient.getQueryData(["UserActivity"]);

      queryClient.setQueryData(["UserActivity"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["UserActivity"], previousValue),
    // After success or failure, refetch the UserActivitys query
    onSettled: () => {
      queryClient.invalidateQueries(["UserActivity"]);
    },
  });
}

function useUserActivitySoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteUserActivity(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["UserActivity"]);

      const previousValue = queryClient.getQueryData(["UserActivity"]) || [];
      queryClient.setQueryData(["UserActivity"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["UserActivity"], previousValue),
    // After success or failure, refetch the UserActivitys query
    onSettled: () => {
      queryClient.invalidateQueries(["UserActivity"]);
    },
  });
}

function useUserActivityMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleUserActivity(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["UserActivity"]);

      const previousValue = queryClient.getQueryData(["UserActivity"]) || [];
      queryClient.setQueryData(["UserActivity"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["UserActivity"], previousValue),
    // After success or failure, refetch the UserActivitys query
    onSettled: () => {
      queryClient.invalidateQueries(["UserActivity"]);
    },
  });
}

function useUserActivityDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteUserActivity(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["UserActivity"]);

      const previousValue = queryClient.getQueryData(["UserActivity"]) || [];
      queryClient.setQueryData(["UserActivity"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["UserActivity"], previousValue),
    // After success or failure, refetch the UserActivitys query
    onSettled: () => {
      queryClient.invalidateQueries(["UserActivity"]);
    },
  });
}

function useUserActivityCount() {
  return useQuery(["UserActivityCount"], () => {
    return getUserActivityCount();
  });
}

function useUserActivityAggregate(record) {
  return useQuery("UserActivity", () => {
    return getUserActivityAggregate(record);
  });
}

function useUserActivityGetById(id) {
  return useQuery(["UserActivity", id], () => {
    return getUserActivityById(id);
  });
}

export {
  useUserActivityList,
  useUserActivityCreate,
  useUserActivityUpdate,
  useUserActivityDelete,
  useUserActivityMultipleSoftDelete,
  useUserActivityCount,
  useUserActivitySoftDelete,
  useUserActivityAggregate,
  useUserActivityGetById,
};
