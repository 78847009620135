import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listWefs,
  createWef,
  updateWef,
  softDeleteWef,
  softDeleteMultipleWef,
  deleteWef,
  getWefAggregate,
  getWefById,
  getWefCount,
} from "../services/Wef.service";

function useWefList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Wef", { page, limit, $and, sort }], () => listWefs(args));
}

function useWefCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createWef(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Wef"]);

      const previousValue = queryClient.getQueryData(["Wef"]) || [];

      queryClient.setQueryData(["Wef"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Wef"], previousValue),
    // After success or failure, refetch the Wefs query
    onSettled: () => {
      queryClient.invalidateQueries(["Wef"]);
    },
  });
}

function useWefUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateWef(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Wef"]);

      const previousValue = queryClient.getQueryData(["Wef"]);

      queryClient.setQueryData(["Wef"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Wef"], previousValue),
    // After success or failure, refetch the Wefs query
    onSettled: () => {
      queryClient.invalidateQueries(["Wef"]);
    },
  });
}

function useWefSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteWef(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Wef"]);

      const previousValue = queryClient.getQueryData(["Wef"]) || [];
      queryClient.setQueryData(["Wef"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Wef"], previousValue),
    // After success or failure, refetch the Wefs query
    onSettled: () => {
      queryClient.invalidateQueries(["Wef"]);
    },
  });
}

function useWefMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleWef(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Wef"]);

      const previousValue = queryClient.getQueryData(["Wef"]) || [];
      queryClient.setQueryData(["Wef"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Wef"], previousValue),
    // After success or failure, refetch the Wefs query
    onSettled: () => {
      queryClient.invalidateQueries(["Wef"]);
    },
  });
}

function useWefDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteWef(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Wef"]);

      const previousValue = queryClient.getQueryData(["Wef"]) || [];
      queryClient.setQueryData(["Wef"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Wef"], previousValue),
    // After success or failure, refetch the Wefs query
    onSettled: () => {
      queryClient.invalidateQueries(["Wef"]);
    },
  });
}

function useWefCount() {
  return useQuery(["WefCount"], () => {
    return getWefCount();
  });
}

function useWefAggregate(record) {
  return useQuery("Wef", () => {
    return getWefAggregate(record);
  });
}

function useWefGetById(id) {
  return useQuery(["Wef", id], () => {
    return getWefById(id);
  });
}

export {
  useWefList,
  useWefCreate,
  useWefUpdate,
  useWefDelete,
  useWefMultipleSoftDelete,
  useWefCount,
  useWefSoftDelete,
  useWefAggregate,
  useWefGetById,
};
