import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listStates,
  createState,
  updateState,
  softDeleteState,
  softDeleteMultipleState,
  deleteState,
  getStateAggregate,
  getStateById,
  getStateCount,
} from "../services/State.service";

function useStateList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["State", { page, limit, $and, sort }], () =>
    listStates(args)
  );
}

function useStateCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createState(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["State"]);

      const previousValue = queryClient.getQueryData(["State"]) || [];

      queryClient.setQueryData(["State"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["State"], previousValue),
    // After success or failure, refetch the States query
    onSettled: () => {
      queryClient.invalidateQueries(["State"]);
    },
  });
}

function useStateUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateState(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["State"]);

      const previousValue = queryClient.getQueryData(["State"]);

      queryClient.setQueryData(["State"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["State"], previousValue),
    // After success or failure, refetch the States query
    onSettled: () => {
      queryClient.invalidateQueries(["State"]);
    },
  });
}

function useStateSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteState(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["State"]);

      const previousValue = queryClient.getQueryData(["State"]) || [];
      queryClient.setQueryData(["State"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["State"], previousValue),
    // After success or failure, refetch the States query
    onSettled: () => {
      queryClient.invalidateQueries(["State"]);
    },
  });
}

function useStateMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleState(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["State"]);

      const previousValue = queryClient.getQueryData(["State"]) || [];
      queryClient.setQueryData(["State"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["State"], previousValue),
    // After success or failure, refetch the States query
    onSettled: () => {
      queryClient.invalidateQueries(["State"]);
    },
  });
}

function useStateDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteState(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["State"]);

      const previousValue = queryClient.getQueryData(["State"]) || [];
      queryClient.setQueryData(["State"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["State"], previousValue),
    // After success or failure, refetch the States query
    onSettled: () => {
      queryClient.invalidateQueries(["State"]);
    },
  });
}

function useStateCount() {
  return useQuery(["StateCount"], () => {
    return getStateCount();
  });
}

function useStateAggregate(record) {
  return useQuery("State", () => {
    return getStateAggregate(record);
  });
}

function useStateGetById(id) {
  return useQuery(["State", id], () => {
    return getStateById(id);
  });
}

export {
  useStateList,
  useStateCreate,
  useStateUpdate,
  useStateDelete,
  useStateMultipleSoftDelete,
  useStateCount,
  useStateSoftDelete,
  useStateAggregate,
  useStateGetById,
};
