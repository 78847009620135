import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listPostReportings,
  createPostReporting,
  updatePostReporting,
  softDeletePostReporting,
  softDeleteMultiplePostReporting,
  deletePostReporting,
  getPostReportingAggregate,
  getPostReportingById,
  getPostReportingCount,
  suspendPostReporting,
  changePostReportingKYCStatus,
  changeKYCDocumentStatus
} from "../services/PostReporting.service";

function usePostReportingList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["PostReporting", { page, limit, $and, sort }], () => listPostReportings(args));
}

function usePostReportingCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createPostReporting(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];

      queryClient.setQueryData(["PostReporting"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function usePostReportingUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updatePostReporting(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]);

      queryClient.setQueryData(["PostReporting"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function usePostReportingSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeletePostReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];
      queryClient.setQueryData(["PostReporting"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function usePostReportingMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultiplePostReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];
      queryClient.setQueryData(["PostReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function usePostReportingDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deletePostReporting(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];
      queryClient.setQueryData(["PostReporting"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function usePostReportingCount() {
  return useQuery(["PostReportingCount"], () => {
    return getPostReportingCount();
  });
}

function usePostReportingAggregate(record) {
  return useQuery("PostReporting", () => {
    return getPostReportingAggregate(record);
  });
}

function usePostReportingGetById(id) {
  return useQuery(["PostReporting", id], () => {
    return getPostReportingById(id);
  });
}

function usePostReportingSuspend() {
  const queryClient = useQueryClient();
  return useMutation((record) => suspendPostReporting(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]);

      queryClient.setQueryData(["PostReporting"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the Users query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function useApprovePostReportingKYC() {
  const queryClient = useQueryClient();
  return useMutation((record) => changePostReportingKYCStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];
      queryClient.setQueryData(["PostReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}

function useUpdateKYCDocument() {
  console.log("useUpdateKYCDocument")
  const queryClient = useQueryClient();
  return useMutation((record) => changeKYCDocumentStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PostReporting"]);

      const previousValue = queryClient.getQueryData(["PostReporting"]) || [];
      queryClient.setQueryData(["PostReporting"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PostReporting"], previousValue),
    // After success or failure, refetch the PostReportings query
    onSettled: () => {
      queryClient.invalidateQueries(["PostReporting"]);
    },
  });
}


export {
  usePostReportingList,
  usePostReportingCreate,
  usePostReportingUpdate,
  usePostReportingDelete,
  usePostReportingMultipleSoftDelete,
  usePostReportingCount,
  usePostReportingSoftDelete,
  usePostReportingAggregate,
  usePostReportingGetById,
  usePostReportingSuspend,
  useApprovePostReportingKYC,
  useUpdateKYCDocument
};
