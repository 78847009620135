import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listFollows,
  createFollow,
  updateFollow,
  softDeleteFollow,
  softDeleteMultipleFollow,
  deleteFollow,
  getFollowAggregate,
  getFollowById,
  getFollowCount,
} from "../services/Follow.service";

function useFollowList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Follow", { page, limit, $and, sort }], () =>
    listFollows(args)
  );
}

function useFollowCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createFollow(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Follow"]);

      const previousValue = queryClient.getQueryData(["Follow"]) || [];

      queryClient.setQueryData(["Follow"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Follow"], previousValue),
    // After success or failure, refetch the Follows query
    onSettled: () => {
      queryClient.invalidateQueries(["Follow"]);
    },
  });
}

function useFollowUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateFollow(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Follow"]);

      const previousValue = queryClient.getQueryData(["Follow"]);

      queryClient.setQueryData(["Follow"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Follow"], previousValue),
    // After success or failure, refetch the Follows query
    onSettled: () => {
      queryClient.invalidateQueries(["Follow"]);
    },
  });
}

function useFollowSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteFollow(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Follow"]);

      const previousValue = queryClient.getQueryData(["Follow"]) || [];
      queryClient.setQueryData(["Follow"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Follow"], previousValue),
    // After success or failure, refetch the Follows query
    onSettled: () => {
      queryClient.invalidateQueries(["Follow"]);
    },
  });
}

function useFollowMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleFollow(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Follow"]);

      const previousValue = queryClient.getQueryData(["Follow"]) || [];
      queryClient.setQueryData(["Follow"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Follow"], previousValue),
    // After success or failure, refetch the Follows query
    onSettled: () => {
      queryClient.invalidateQueries(["Follow"]);
    },
  });
}

function useFollowDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteFollow(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Follow"]);

      const previousValue = queryClient.getQueryData(["Follow"]) || [];
      queryClient.setQueryData(["Follow"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Follow"], previousValue),
    // After success or failure, refetch the Follows query
    onSettled: () => {
      queryClient.invalidateQueries(["Follow"]);
    },
  });
}

function useFollowCount() {
  return useQuery(["FollowCount"], () => {
    return getFollowCount();
  });
}

function useFollowAggregate(record) {
  return useQuery("Follow", () => {
    return getFollowAggregate(record);
  });
}

function useFollowGetById(id) {
  return useQuery(["Follow", id], () => {
    return getFollowById(id);
  });
}

export {
  useFollowList,
  useFollowCreate,
  useFollowUpdate,
  useFollowDelete,
  useFollowMultipleSoftDelete,
  useFollowCount,
  useFollowSoftDelete,
  useFollowAggregate,
  useFollowGetById,
};
