import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const ProjectConfigReducer = createSlice({
  name: "ProjectConfig",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("ProjectConfigReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = ProjectConfigReducer;
export const { defaultAction } = actions;
export default reducer;
