import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listAgents,
  createAgent,
  updateAgent,
  softDeleteAgent,
  softDeleteMultipleAgent,
  deleteAgent,
  getAgentAggregate,
  getAgentById,
  getAgentCount,
  suspendAgent,
  changeAgentKYCStatus,
  changeKYCDocumentStatus
} from "../services/Agent.service";

function useAgentList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Agent", { page, limit, $and, sort }], () => listAgents(args));
}

function useAgentCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createAgent(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];

      queryClient.setQueryData(["Agent"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useAgentUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateAgent(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]);

      queryClient.setQueryData(["Agent"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useAgentSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteAgent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];
      queryClient.setQueryData(["Agent"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useAgentMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleAgent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];
      queryClient.setQueryData(["Agent"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useAgentDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteAgent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];
      queryClient.setQueryData(["Agent"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useAgentCount() {
  return useQuery(["AgentCount"], () => {
    return getAgentCount();
  });
}

function useAgentAggregate(record) {
  return useQuery("Agent", () => {
    return getAgentAggregate(record);
  });
}

function useAgentGetById(id) {
  return useQuery(["Agent", id], () => {
    return getAgentById(id);
  });
}

function useAgentSuspend() {
  const queryClient = useQueryClient();
  return useMutation((record) => suspendAgent(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]);

      queryClient.setQueryData(["Agent"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Users query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useApproveAgentKYC() {
  const queryClient = useQueryClient();
  return useMutation((record) => changeAgentKYCStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];
      queryClient.setQueryData(["Agent"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}

function useUpdateKYCDocument() {
  console.log("useUpdateKYCDocument")
  const queryClient = useQueryClient();
  return useMutation((record) => changeKYCDocumentStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Agent"]);

      const previousValue = queryClient.getQueryData(["Agent"]) || [];
      queryClient.setQueryData(["Agent"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Agent"], previousValue),
    // After success or failure, refetch the Agents query
    onSettled: () => {
      queryClient.invalidateQueries(["Agent"]);
    },
  });
}


export {
  useAgentList,
  useAgentCreate,
  useAgentUpdate,
  useAgentDelete,
  useAgentMultipleSoftDelete,
  useAgentCount,
  useAgentSoftDelete,
  useAgentAggregate,
  useAgentGetById,
  useAgentSuspend,
  useApproveAgentKYC,
  useUpdateKYCDocument
};
