import React, { Fragment, useState } from 'react';
import CKEditors from "react-ckeditor-component";
import Breadcrumb from '../common/Breadcrumb';
import { CKEditorExample } from "../../constant/common";

export const RichTextEditor = () => {
    const [content, setContent] = useState('content')
    const onChange = (evt) => {
        const newContent = evt.editor.getData();
        setContent(newContent)
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <CKEditors
                                    activeclassName="p10"
                                    content={content}
                                    events={{
                                        "change": onChange
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}