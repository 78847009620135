import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { projectConfig } = API_URLS;

export const listProjectConfigs = (payload) => {
  return apiClient({ url: projectConfig.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createProjectConfig = (payload) => {
  return apiClient({ url: projectConfig.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateProjectConfig = (payload) => {
  return apiClient({
    url: projectConfig.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteProjectConfig = (payload) => {
  return apiClient({
    url: projectConfig.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteProjectConfig = (payload) => {
  return apiClient({
    url: projectConfig.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProjectConfigCount = () => {
  return apiClient({ url: projectConfig.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProjectConfigAggregate = (payload) => {
  return apiClient({ url: projectConfig.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getProjectConfigById = (payload) => {
  return apiClient({
    url: projectConfig.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleProjectConfig = (payload) => {
  return apiClient({
    url: projectConfig.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
