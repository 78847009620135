import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const AreaReducer = createSlice({
  name: "Area",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("AreaReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = AreaReducer;
export const { defaultAction } = actions;
export default reducer;
