import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listEvents,
  createEvent,
  updateEvent,
  softDeleteEvent,
  softDeleteMultipleEvent,
  deleteEvent,
  getEventAggregate,
  getEventById,
  getEventCount,
} from "../services/Event.service";

function useEventList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Event", { page, limit, $and, sort }], () =>
    listEvents(args)
  );
}

function useEventCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createEvent(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Event"]);

      const previousValue = queryClient.getQueryData(["Event"]) || [];

      queryClient.setQueryData(["Event"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Event"], previousValue),
    // After success or failure, refetch the Events query
    onSettled: () => {
      queryClient.invalidateQueries(["Event"]);
    },
  });
}

function useEventUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateEvent(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Event"]);

      const previousValue = queryClient.getQueryData(["Event"]);

      queryClient.setQueryData(["Event"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Event"], previousValue),
    // After success or failure, refetch the Events query
    onSettled: () => {
      queryClient.invalidateQueries(["Event"]);
    },
  });
}

function useEventSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteEvent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Event"]);

      const previousValue = queryClient.getQueryData(["Event"]) || [];
      queryClient.setQueryData(["Event"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Event"], previousValue),
    // After success or failure, refetch the Events query
    onSettled: () => {
      queryClient.invalidateQueries(["Event"]);
    },
  });
}

function useEventMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleEvent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Event"]);

      const previousValue = queryClient.getQueryData(["Event"]) || [];
      queryClient.setQueryData(["Event"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Event"], previousValue),
    // After success or failure, refetch the Events query
    onSettled: () => {
      queryClient.invalidateQueries(["Event"]);
    },
  });
}

function useEventDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteEvent(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Event"]);

      const previousValue = queryClient.getQueryData(["Event"]) || [];
      queryClient.setQueryData(["Event"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Event"], previousValue),
    // After success or failure, refetch the Events query
    onSettled: () => {
      queryClient.invalidateQueries(["Event"]);
    },
  });
}

function useEventCount() {
  return useQuery(["EventCount"], () => {
    return getEventCount();
  });
}

function useEventAggregate(record) {
  return useQuery("Event", () => {
    return getEventAggregate(record);
  });
}

function useEventGetById(id) {
  return useQuery(["Event", id], () => {
    return getEventById(id);
  });
}

export {
  useEventList,
  useEventCreate,
  useEventUpdate,
  useEventDelete,
  useEventMultipleSoftDelete,
  useEventCount,
  useEventSoftDelete,
  useEventAggregate,
  useEventGetById,
};
