import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listProjectConfigs,
  createProjectConfig,
  updateProjectConfig,
  softDeleteProjectConfig,
  softDeleteMultipleProjectConfig,
  deleteProjectConfig,
  getProjectConfigAggregate,
  getProjectConfigById,
  getProjectConfigCount,
} from "../services/ProjectConfig.service";

function useProjectConfigList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["ProjectConfig", { page, limit, $and, sort }], () =>
    listProjectConfigs(args)
  );
}

function useProjectConfigCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createProjectConfig(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["ProjectConfig"]);

      const previousValue = queryClient.getQueryData(["ProjectConfig"]) || [];

      queryClient.setQueryData(["ProjectConfig"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProjectConfig"], previousValue),
    // After success or failure, refetch the ProjectConfigs query
    onSettled: () => {
      queryClient.invalidateQueries(["ProjectConfig"]);
    },
  });
}

function useProjectConfigUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateProjectConfig(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["ProjectConfig"]);

      const previousValue = queryClient.getQueryData(["ProjectConfig"]);

      queryClient.setQueryData(["ProjectConfig"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProjectConfig"], previousValue),
    // After success or failure, refetch the ProjectConfigs query
    onSettled: () => {
      queryClient.invalidateQueries(["ProjectConfig"]);
    },
  });
}

function useProjectConfigSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteProjectConfig(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProjectConfig"]);

      const previousValue = queryClient.getQueryData(["ProjectConfig"]) || [];
      queryClient.setQueryData(["ProjectConfig"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProjectConfig"], previousValue),
    // After success or failure, refetch the ProjectConfigs query
    onSettled: () => {
      queryClient.invalidateQueries(["ProjectConfig"]);
    },
  });
}

function useProjectConfigMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleProjectConfig(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProjectConfig"]);

      const previousValue = queryClient.getQueryData(["ProjectConfig"]) || [];
      queryClient.setQueryData(["ProjectConfig"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProjectConfig"], previousValue),
    // After success or failure, refetch the ProjectConfigs query
    onSettled: () => {
      queryClient.invalidateQueries(["ProjectConfig"]);
    },
  });
}

function useProjectConfigDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteProjectConfig(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["ProjectConfig"]);

      const previousValue = queryClient.getQueryData(["ProjectConfig"]) || [];
      queryClient.setQueryData(["ProjectConfig"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["ProjectConfig"], previousValue),
    // After success or failure, refetch the ProjectConfigs query
    onSettled: () => {
      queryClient.invalidateQueries(["ProjectConfig"]);
    },
  });
}

function useProjectConfigCount() {
  return useQuery(["ProjectConfigCount"], () => {
    return getProjectConfigCount();
  });
}

function useProjectConfigAggregate(record) {
  return useQuery("ProjectConfig", () => {
    return getProjectConfigAggregate(record);
  });
}

function useProjectConfigGetById(id) {
  return useQuery(["ProjectConfig", id], () => {
    return getProjectConfigById(id);
  });
}

export {
  useProjectConfigList,
  useProjectConfigCreate,
  useProjectConfigUpdate,
  useProjectConfigDelete,
  useProjectConfigMultipleSoftDelete,
  useProjectConfigCount,
  useProjectConfigSoftDelete,
  useProjectConfigAggregate,
  useProjectConfigGetById,
};
