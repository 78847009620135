import React from "react";
import Modal from "react-modal";

export const Popup = ({
  popupTitle,
  isOpen,
  handleCancel,
  children,
  popupbody,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className="customPopup"
      overlayClassName="popupWrap"
      appElement={document.getElementById("root")}
    >
      <div className="popupBodyWrap">
        <div className="page-body m-0 p-0 h-auto">
          <div className="py-3 px-4 drawerHead">
            <h4 className="mb-0">{popupTitle ? popupTitle : "Change complaint status"}</h4>
            {handleCancel && (
              <div className="drawerClose" onClick={handleCancel}>
                <i className="fa fa-close"></i>
              </div>
            )}
          </div>
          <div className={`popupChild ${popupbody}`}>{children}</div>
          
        </div>
      </div>
    </Modal>
  );
};
