import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const FollowReducer = createSlice({
  name: "Follow",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("FollowReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = FollowReducer;
export const { defaultAction } = actions;
export default reducer;
