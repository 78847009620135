import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const CommitteeReducer = createSlice({
  name: "Committee",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("CommitteeReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = CommitteeReducer;
export const { defaultAction } = actions;
export default reducer;
