import {Bookmark, Database,Link2,Archive,Home, User,Users,Layers,Settings,Calendar,Info,BookOpen,Briefcase } from 'react-feather';
import { STORAGE_KEY } from '../../../constant/common';
import { isMocking } from '../../../utils/utils';
export const menuitems = [
	{
		title: 'Profile',
		icon: Briefcase,
		type: 'link',
		path: '/profile',
		badgeType: 'primary',
		active: false,
		modelName: 'party',
	},
	{
		title: 'User',
		icon: User,
		type: 'link',
		path: '/User',
		badgeType: 'primary',
		active: false,
		modelName: 'user',
	},
	{
		title: 'Blogs',
		icon: Archive,
		type: 'sub',
		//path: "/Posts",
		badgeType: 'primary',
		active: false,
		modelName: 'posts',
		children: [
			{
				title: 'Posts',
				type: 'link',
				path: '/Posts',
			},
			{
				title: 'Comments',
				type: 'link',
				path: '/Comments',
			},
			{
				title: 'Achivements',
				type: 'link',
				path: '/achivements',
			},
		],
	},
	{
		title: 'Complaint',
		icon: Info,
		type: 'sub',
		//path: "/Complaint",
		badgeType: 'primary',
		active: false,
		modelName: 'complaint',
		children: [
			{
				title: 'Complaints',
				type: 'link',
				path: '/Complaint',
			},
			{
				title: 'Dispute',
				type: 'link',
				path: '/disputecomplaint',
			},
		],
	},
	{
		title: 'Event',
		icon: Calendar,
		type: 'link',
		path: '/Event',
		badgeType: 'primary',
		active: false,
		modelName: 'event',
	},
	{
		title: 'WEF',
		icon: Layers,
		type: 'link',
		path: '/Wef',
		badgeType: 'primary',
		active: false,
		modelName: 'wef',
	},
	{
		title: 'Document',
		icon: Bookmark,
		type: 'link',
		path: '/Document',
		badgeType: 'primary',
		active: false,
		modelName: 'document',
	},
	{
		title: 'Follow',
		icon: Home,
		type: 'link',
		path: '/Follow',
		badgeType: 'primary',
		active: false,
		modelName: 'follow',
	},
	{
		title: 'Committee',
		icon: Users,
		type: 'link',
		path: '/Committee',
		badgeType: 'primary',
		active: false,
		modelName: 'committee',
	},
	// {
	//   title: "Master",
	//   icon: Home,
	//   type: "link",
	//   path: "/Master",
	//   badgeType: "primary",
	//   active: false,
	//   modelName: "master"
	// },
	{
		title: 'PartySurvey',
		icon: Home,
		type: 'link',
		path: '/PartySurvey',
		badgeType: 'primary',
		active: false,
		modelName: 'partySurvey',
	},
	{
		title: 'Questionaire',
		icon: Home,
		type: 'link',
		path: '/Questionaire',
		badgeType: 'primary',
		active: false,
		modelName: 'questionaire',
	},
	// {
	//   title: "Contract",
	//   icon: Home,
	//   type: "link",
	//   path: "/Contract",
	//   badgeType: "primary",
	//   active: false,
	//   modelName: "contract"
	// },
	{
		title: 'Handler',
		icon: Link2,
		type: 'link',
		path: '/Handler',
		badgeType: 'primary',
		active: false,
		modelName: 'handler',
	},
	// {
	// 	title: "Agent",
	// 	icon: Home,
	// 	type: "link",
	// 	path: "/agent",
	// 	badgeType: "primary",
	// 	active: false,
	// 	modelName: "Agent"
	// },
	{
		title: 'Agent',
		icon: User,
		type: 'sub',
		//path: "/Complaint",
		badgeType: 'primary',
		active: false,
		modelName: 'Agent',
		children: [
			{ title: 'Agent List', type: 'link', path: '/agent' },
			{ title: 'Agent Ledger', type: 'link', path: '/AgentLedger' }
		],
	},
	{
		title: "Configuration",
		icon: Settings,
		type: "link",
		path: "/configuration",
		badgeType: "primary",
		active: false,
		modelName: "handler"
	},
	// {
	// 	title: "RSS Feed",
	// 	icon: Settings,
	// 	type: "link",
	// 	path: "/rssFeed",
	// 	badgeType: "primary",
	// 	active: false,
	// 	modelName: "handler"
	// },
	{
		title: 'DataSet',
		icon: Database,
		type: 'sub',
		//path: "/ProjectConfig",
		badgeType: 'primary',
		active: false,
		modelName: 'projectConfig',
		children: [
			{ title: 'State', type: 'link', path: '/State' },
			{ title: 'City', type: 'link', path: '/City' },
			{ title: 'Area', type: 'link', path: '/Area' },
			{ title: 'Master', type: 'link', path: '/Master' },
			{ title: 'Contract', type: 'link', path: '/Contract' },
		],
	},
	{
		title: "Inquiry",
		icon: Info,
		type: "link",
		path: "/inquiry",
		badgeType: "primary",
		active: false,
		modelName: "handler"
	},
	// {
	// 	title: "Reported Profile",
	// 	icon: Info,
	// 	type: "link",
	// 	path: "/reportProfile",
	// 	badgeType: "primary",
	// 	active: false,
	// 	modelName: "reportedProfile"
	// },
	{
		title: "Report Reason",
		icon: Home,
		type: "link",
		path: "/reportReason",
		badgeType: "primary",
		active: false,
		modelName: "handler"
	  },
	{
	  title: "Reported Profile",
	  icon: Home,
	  type: "link",
	  path: "/reportedProfile",
	  badgeType: "primary",
	  active: false,
	  modelName: "handler"
	},
	
	  {
		title: "Reported Post",
		icon: Home,
		type: "link",
		path: "/reportedPost",
		badgeType: "primary",
		active: false,
		modelName: "handler"
	  }
	// , {
	//   title: "AppVersion",
	//   icon: Home,
	//   type: "link",
	//   path: "/AppVersion",
	//   badgeType: "primary",
	//   active: true,
	//   modelName: "appVersion"
	// },{
	//   title: "ProjectConfig",
	//   icon: Home,
	//   type: "link",
	//   path: "/ProjectConfig",
	//   badgeType: "primary",
	//   active: false,
	//   modelName: "projectConfig"
	// }
];
export const filterPermission = () => {
	const rolePermissionObj =
		JSON.parse(localStorage.getItem(STORAGE_KEY.ROLE_ACCESS)) || {};
	const permissions = Object.keys(rolePermissionObj) || [];
	return (
		menuitems.filter(
			(item) =>
				permissions.includes(item.modelName) &&
				rolePermissionObj[item.modelName].length > 0
		) || []
	);
};
export const MENUITEMS = isMocking ? menuitems : filterPermission();
