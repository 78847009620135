import React from "react";
import WefDetails from "./pages/Wef/WefDetails";
import WefContainer from "./pages/Wef/WefContainer";
import UserActivityDetails from "./pages/UserActivity/UserActivityDetails";
import UserActivityContainer from "./pages/UserActivity/UserActivityContainer";
import UserDetails from "./pages/User/UserDetails";
import UserContainer from "./pages/User/UserContainer";
import StateDetails from "./pages/State/StateDetails";
import StateContainer from "./pages/State/StateContainer";
import QuestionaireDetails from "./pages/Questionaire/QuestionaireDetails";
import QuestionaireContainer from "./pages/Questionaire/QuestionaireContainer";
import ProjectConfigDetails from "./pages/ProjectConfig/ProjectConfigDetails";
import ProjectConfigContainer from "./pages/ProjectConfig/ProjectConfigContainer";
import PostsDetails from "./pages/Posts/PostsDetails";
import PostsContainer from "./pages/Posts/PostsContainer";
import PartySurveyDetails from "./pages/PartySurvey/PartySurveyDetails";
import PartySurveyContainer from "./pages/PartySurvey/PartySurveyContainer";
import PartyDetails from "./pages/Party/PartyDetails";
import PartyContainer from "./pages/Party/PartyContainer";
import MasterDetails from "./pages/Master/MasterDetails";
import MasterContainer from "./pages/Master/MasterContainer";
import FollowDetails from "./pages/Follow/FollowDetails";
import FollowContainer from "./pages/Follow/FollowContainer";
import EventDetails from "./pages/Event/EventDetails";
import EventContainer from "./pages/Event/EventContainer";
import DocumentDetails from "./pages/Document/DocumentDetails";
import DocumentContainer from "./pages/Document/DocumentContainer";
import ContractDetails from "./pages/Contract/ContractDetails";
import ContractContainer from "./pages/Contract/ContractContainer";
import ComplaintDetails from "./pages/Complaint/ComplaintDetails";
import ComplaintContainer from "./pages/Complaint/ComplaintContainer";
import CommitteeDetails from "./pages/Committee/CommitteeDetails";
import CommitteeContainer from "./pages/Committee/CommitteeContainer";
import CommentsDetails from "./pages/Comments/CommentsDetails";
import CommentsContainer from "./pages/Comments/CommentsContainer";
import CityDetails from "./pages/City/CityDetails";
import CityContainer from "./pages/City/CityContainer";
import AreaDetails from "./pages/Area/AreaDetails";
import AgentDetails from "./pages/Agent/AgentDetails";
import AreaContainer from "./pages/Area/AreaContainer";
import AppVersionDetails from "./pages/AppVersion/AppVersionDetails";
import AppVersionContainer from "./pages/AppVersion/AppVersionContainer";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Error400 from "./pages/errors/error400";
import UpdateProfile from "./pages/auth/UpdateProfile";
import ChangePassword from "./pages/auth/ChangePassword";
import App from "./components/app";
import { MENUITEMS } from "./components/common/Sidebar/menu";
import { isMocking } from "./utils/utils";
import AchivementContainer from "./pages/Achivement/AchievementContainer";
import AchivementDetails from "./pages/Achivement/AchievementDetails";
import DisputeComplaintContainer from "./pages/DisputeComplaint/DisputeComplaintContainer";
import DisputeComplaintDetails from "./pages/DisputeComplaint/DisputeComplaintDetails";
import HandlerContainer from "./pages/Handler/HandlerContainer";
import HandlerDetails from "./pages/Handler/HandlerDetails";
import AgentContainer from "./pages/Agent/AgentContainer";
import ConfigurationContainer from "./pages/Configuration/ConfigurationContainer";
import AgentLadgerContainer from "./pages/AgentLadger/AgentLadgerContainer";
import AgentLadgerDetails from "./pages/AgentLadger/AgentLadgerDetails";
import RssFeedDetails from "./pages/RssFeed/RssFeedDetails";
import RssFeedContainer from "./pages/RssFeed/RssFeedContainer";
import InquiryContainer from "./pages/inquiry/InquiryContainer";
import InquiryDetails from "./pages/inquiry/InquiryDetails";
import ProfileReportingContainer from "./pages/ProfileReporting/ProfileReportingContainer";
import ProfileReportingDetails from "./pages/ProfileReporting/ProfileReportingDetails";

import ReportReasonContainer from "./pages/ReportReason/ReportReasonContainer"
import ReportReasonDetails from "./pages/ReportReason/ReportReasonDetails";

import PostReportingContainer from "./pages/PostReporting/PostReportingContainer"
import PostReportingDetails from "./pages/PostReporting/PostReportingDetails";
const Router = () => {
  return (
    <BrowserRouter basename={`/`}>
      <Switch>
        <Route path={`/login`} component={Login} />
        <Route path={`/signup`} component={Signup} />
        <Route path={`/forgot-password`} component={ForgotPassword} />
        <Route path={`/reset-password/:tokenId`} component={ResetPassword} />
        <Route path={`/errors/error400`} component={Error400} />
        <App>
          <Route
            exact
            path="/"
            render={() =>
              isMocking ? <Redirect to={`${MENUITEMS[0]?.path}`} /> : null
            }
          />
          <Route path={`/update-profile`} component={UpdateProfile} />
          <Route path={`/change-password`} component={ChangePassword} />
          <Route exact path="/Appversion" component={AppVersionContainer} />
          <Route
            path="/Appversion/:id"
            render={(props) => (
              <AppVersionDetails {...props} modelName={"appVersion"} />
            )}
          />
          <Route exact path="/Area" component={AreaContainer} />
          <Route
            path="/Area/:id"
            render={(props) => <AreaDetails {...props} modelName={"area"} />}
          />
          <Route exact path="/City" component={CityContainer} />
          <Route
            path="/City/:id"
            render={(props) => <CityDetails {...props} modelName={"city"} />}
          />
          <Route exact path="/Comments" component={CommentsContainer} />
          <Route
            path="/Comments/:id"
            render={(props) => (
              <CommentsDetails {...props} modelName={"comments"} />
            )}
          />
          <Route exact path="/achivements" component={AchivementContainer} />
          <Route
            path="/achivements/:id"
            render={(props) => (
              <AchivementDetails {...props} modelName={"posts"} />
            )}
          />
          <Route exact path="/Committee" component={CommitteeContainer} />
          <Route
            path="/Committee/:id"
            render={(props) => (
              <CommitteeDetails {...props} modelName={"committee"} />
            )}
          />
          <Route exact path="/Complaint" component={ComplaintContainer} />
          <Route
            path="/Complaint/:id"
            render={(props) => (
              <ComplaintDetails {...props} modelName={"complaint"} />
            )}
          />
          <Route exact path="/disputecomplaint" component={DisputeComplaintContainer} />
          <Route
            path="/disputecomplaint/:id"
            render={(props) => (
              <DisputeComplaintDetails {...props} modelName={"complaint"} />
            )}
          />
          <Route exact path="/Contract" component={ContractContainer} />
          <Route
            path="/Contract/:id"
            render={(props) => (
              <ContractDetails {...props} modelName={"contract"} />
            )}
          />
          <Route exact path="/Document" component={DocumentContainer} />
          <Route
            path="/Document/:id"
            render={(props) => (
              <DocumentDetails {...props} modelName={"document"} />
            )}
          />
          <Route exact path="/Event" component={EventContainer} />
          <Route
            path="/Event/:id"
            render={(props) => <EventDetails {...props} modelName={"event"} />}
          />
          <Route exact path="/Follow" component={FollowContainer} />
          <Route
            path="/Follow/:id"
            render={(props) => (
              <FollowDetails {...props} modelName={"follow"} />
            )}
          />
          <Route exact path="/Master" component={MasterContainer} />
          <Route
            path="/Master/:id"
            render={(props) => (
              <MasterDetails {...props} modelName={"master"} />
            )}
          />
          <Route exact path="/profile" component={PartyContainer} />
          <Route
            path="/profile/:id"
            render={(props) => <PartyDetails {...props} modelName={"party"} />}
          />
          <Route exact path="/Partysurvey" component={PartySurveyContainer} />
          <Route
            path="/Partysurvey/:id"
            render={(props) => (
              <PartySurveyDetails {...props} modelName={"partySurvey"} />
            )}
          />
          <Route exact path="/Posts" component={PostsContainer} />
          <Route
            path="/Posts/:id"
            render={(props) => <PostsDetails {...props} modelName={"posts"} />}
          />
          <Route
            exact
            path="/Projectconfig"
            component={ProjectConfigContainer}
          />
          <Route exact path="/configuration" component={ConfigurationContainer} />
          <Route
            path="/Projectconfig/:id"
            render={(props) => (
              <ProjectConfigDetails {...props} modelName={"projectConfig"} />
            )}
          />
          <Route exact path="/Questionaire" component={QuestionaireContainer} />
          <Route
            path="/Questionaire/:id"
            render={(props) => (
              <QuestionaireDetails {...props} modelName={"questionaire"} />
            )}
          />
          <Route exact path="/State" component={StateContainer} />
          <Route
            path="/State/:id"
            render={(props) => <StateDetails {...props} modelName={"state"} />}
          />
          <Route exact path="/User" component={UserContainer} />
          <Route
            path="/User/:id"
            render={(props) => <UserDetails {...props} modelName={"user"} />}
          />
          <Route exact path="/Useractivity" component={UserActivityContainer} />
          <Route
            path="/Useractivity/:id"
            render={(props) => (
              <UserActivityDetails {...props} modelName={"userActivity"} />
            )}
          />
          <Route exact path="/Wef" component={WefContainer} />
          <Route
            path="/Wef/:id"
            render={(props) => <WefDetails {...props} modelName={"wef"} />}
          />
          <Route exact path="/Handler" component={HandlerContainer} />
          <Route
            path="/Handler/:id"
            render={(props) => <HandlerDetails {...props} modelName={"handler"} />}
          />
           <Route
            path="/Agent/:id"
            render={(props) => <AgentDetails {...props} modelName={"agent"} />}
          />
           <Route exact path="/Agent" component={AgentContainer} />
           <Route
            path="/agentLedger/:id"
            render={(props) => <AgentLadgerDetails {...props} modelName={"AgentLadger"} />}
          />
           <Route exact path="/agentLedger" component={AgentLadgerContainer} />
           <Route
            path="/rssFeed/:id"
            render={(props) => <RssFeedDetails {...props} modelName={"AgentLadger"} />}
          />
           <Route exact path="/rssFeed" component={RssFeedContainer} />


           <Route
            path="/inquiry/:id"
            render={(props) => <InquiryDetails {...props} modelName={"Inquiry"} />}
          />
           <Route exact path="/inquiry" component={InquiryContainer} />

           <Route
            path="/reportedProfile/:id"
            render={(props) => <ProfileReportingDetails {...props} modelName={"ProfileReporting"} />}
          />
           <Route exact path="/reportedProfile" component={ProfileReportingContainer} />

           <Route
            path="/reason/:id"
            render={(props) => <ReportReasonDetails {...props} modelName={"ReportReason"} />}
          />
           <Route exact path="/reportReason" component={ReportReasonContainer} />

           <Route
            path="/reportedPost/:id"
            render={(props) => <PostReportingDetails {...props} modelName={"ReportedPost"} />}
          />
           <Route exact path="/reportedPost" component={PostReportingContainer} />
        </App>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
