import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { partySurvey } = API_URLS;

export const listPartySurveys = (payload) => {
  return apiClient({ url: partySurvey.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createPartySurvey = (payload) => {
  return apiClient({ url: partySurvey.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updatePartySurvey = (payload) => {
  return apiClient({
    url: partySurvey.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeletePartySurvey = (payload) => {
  return apiClient({
    url: partySurvey.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deletePartySurvey = (payload) => {
  return apiClient({
    url: partySurvey.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartySurveyCount = () => {
  return apiClient({ url: partySurvey.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartySurveyAggregate = (payload) => {
  return apiClient({ url: partySurvey.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPartySurveyById = (payload) => {
  return apiClient({
    url: partySurvey.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultiplePartySurvey = (payload) => {
  return apiClient({
    url: partySurvey.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
