import { apiClient } from "../api/client";
import { API_URLS } from "../api/config";
const { Agent } = API_URLS;

export const listAgents = (payload) => {
  return apiClient({ url: Agent.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createAgent = (payload) => {
  payload.isSubAgent = false
  payload.isKYCDocumentUploaded = true
  payload.isKYC = true
  return apiClient({ url: Agent.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateAgent = (payload) => {
  return apiClient({
    url: Agent.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteAgent = (payload) => {
  return apiClient({
    url: Agent.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteAgent = (payload) => {
  return apiClient({
    url: Agent.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAgentCount = () => {
  return apiClient({ url: Agent.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAgentAggregate = (payload) => {
  return apiClient({ url: Agent.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAgentById = (payload) => {  
  return apiClient({
    url: Agent.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleAgent = (payload) => {
  return apiClient({
    url: Agent.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const suspendAgent = (payload) => {
  console.log("payload.id",payload.id)
  return apiClient({
    url: Agent.suspend_unSuspend,
    data: {userId:payload.id},
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't suspend/unsuspend record.");
    });
};

export const changeAgentKYCStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: Agent.kycApprove + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeKYCDocumentStatus = (payload) => {
  console.log("payload",payload)
  return apiClient({
    url: Agent.uploadKycDoc + payload.id,
    data: { query: { isActive: true, isDeleted: false } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};