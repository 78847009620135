import { apiClient } from "../api/client";
import { API_URLS } from "../api/config";
const { Inquiry } = API_URLS;

export const listInquirys = (payload) => {
  return apiClient({ url: Inquiry.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createInquiry = (payload) => {
  return apiClient({ url: Inquiry.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateInquiry = (payload) => {
  return apiClient({
    url: Inquiry.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteInquiry = (payload) => {
  return apiClient({
    url: Inquiry.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteInquiry = (payload) => {
  return apiClient({
    url: Inquiry.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getInquiryCount = () => {
  return apiClient({ url: Inquiry.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getInquiryAggregate = (payload) => {
  return apiClient({ url: Inquiry.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getInquiryById = (payload) => {
  return apiClient({
    url: Inquiry.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleInquiry = (payload) => {
  return apiClient({
    url: Inquiry.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
