export const TOKEN_KEY = "authToken";
export const REFRESH_TOKEN_KEY = "refreshToken"; // Change URL as per your server configuration

let serviceUrl = process.env.REACT_APP_SERVICE_URL;
const BASE_URL = `${serviceUrl}/admin`;
console.log("BASE_URL",BASE_URL)
export const UPLOAD_URL = `${BASE_URL}/upload`;
export const API_URLS = {
  auth: {
    url: `${BASE_URL}`,
    login: `${BASE_URL}/auth/login`,
    logout: `${BASE_URL}/auth/logout`,
    resetPassword: `${BASE_URL}/auth/reset-password`,
    forgotPassword: `${BASE_URL}/auth/forgot-password`,
    register: `${BASE_URL}/auth/register`,
    validateOtp: `${BASE_URL}/auth/validate-otp`,
    changePassword: `${BASE_URL}/user/change-password/`,
    updateProfile: `${BASE_URL}/user/update-profile/`,
    login: `${BASE_URL}/auth/login/`,
  },
  appVersion: {
    create: `${BASE_URL}/appVersion/create`,
    update: `${BASE_URL}/appVersion/update/`,
    delete: `${BASE_URL}/appVersion/delete/`,
    list: `${BASE_URL}/appVersion/list`,
    count: `${BASE_URL}/appVersion/count`,
    aggregate: `${BASE_URL}/appVersion/aggregate`,
    softdelete: `${BASE_URL}/appVersion/softDelete/`,
    multisoftdelete: `${BASE_URL}/appVersion/softDeleteMany/`,
    singlerecord: `${BASE_URL}/appVersion/`,
  },
  area: {
    create: `${BASE_URL}/area/create`,
    update: `${BASE_URL}/area/update/`,
    delete: `${BASE_URL}/area/delete/`,
    list: `${BASE_URL}/area/list`,
    count: `${BASE_URL}/area/count`,
    aggregate: `${BASE_URL}/area/aggregate`,
    softdelete: `${BASE_URL}/area/softDelete/`,
    multisoftdelete: `${BASE_URL}/area/softDeleteMany/`,
    singlerecord: `${BASE_URL}/area/`,
  },
  city: {
    create: `${BASE_URL}/city/create`,
    update: `${BASE_URL}/city/update/`,
    delete: `${BASE_URL}/city/delete/`,
    list: `${BASE_URL}/city/list`,
    count: `${BASE_URL}/city/count`,
    aggregate: `${BASE_URL}/city/aggregate`,
    softdelete: `${BASE_URL}/city/softDelete/`,
    multisoftdelete: `${BASE_URL}/city/softDeleteMany/`,
    singlerecord: `${BASE_URL}/city/`,
  },
  pinCode: {
    list: `${BASE_URL}/pinCode/list`,
    count: `${BASE_URL}/pinCode/count`,
    aggregate: `${BASE_URL}/pinCode/aggregate`,
    singlerecord: `${BASE_URL}/pinCode/`,
  },
  comments: {
    create: `${BASE_URL}/comments/create`,
    update: `${BASE_URL}/comments/update/`,
    delete: `${BASE_URL}/comments/delete/`,
    list: `${BASE_URL}/comments/list`,
    count: `${BASE_URL}/comments/count`,
    aggregate: `${BASE_URL}/comments/aggregate`,
    softdelete: `${BASE_URL}/comments/softDelete/`,
    multisoftdelete: `${BASE_URL}/comments/softDeleteMany/`,
    singlerecord: `${BASE_URL}/comments/`,
  },
  committee: {
    create: `${BASE_URL}/committee/create`,
    update: `${BASE_URL}/committee/update/`,
    delete: `${BASE_URL}/committee/delete/`,
    list: `${BASE_URL}/committee/list`,
    count: `${BASE_URL}/committee/count`,
    aggregate: `${BASE_URL}/committee/aggregate`,
    softdelete: `${BASE_URL}/committee/softDelete/`,
    multisoftdelete: `${BASE_URL}/committee/softDeleteMany/`,
    singlerecord: `${BASE_URL}/committee/`,
  },
  complaint: {
    create: `${BASE_URL}/complaint/create`,
    update: `${BASE_URL}/complaint/update/`,
    delete: `${BASE_URL}/complaint/delete/`,
    list: `${BASE_URL}/complaint/list`,
    count: `${BASE_URL}/complaint/count`,
    aggregate: `${BASE_URL}/complaint/aggregate`,
    softdelete: `${BASE_URL}/complaint/softDelete/`,
    multisoftdelete: `${BASE_URL}/complaint/softDeleteMany/`,
    singlerecord: `${BASE_URL}/complaint/`,
  },
  contract: {
    create: `${BASE_URL}/contract/create`,
    update: `${BASE_URL}/contract/update/`,
    delete: `${BASE_URL}/contract/delete/`,
    list: `${BASE_URL}/contract/list`,
    count: `${BASE_URL}/contract/count`,
    aggregate: `${BASE_URL}/contract/aggregate`,
    softdelete: `${BASE_URL}/contract/softDelete/`,
    multisoftdelete: `${BASE_URL}/contract/softDeleteMany/`,
    singlerecord: `${BASE_URL}/contract/`,
  },
  document: {
    create: `${BASE_URL}/document/create`,
    update: `${BASE_URL}/document/update/`,
    delete: `${BASE_URL}/document/delete/`,
    list: `${BASE_URL}/document/list`,
    count: `${BASE_URL}/document/count`,
    aggregate: `${BASE_URL}/document/aggregate`,
    softdelete: `${BASE_URL}/document/softDelete/`,
    multisoftdelete: `${BASE_URL}/document/softDeleteMany/`,
    singlerecord: `${BASE_URL}/document/`,
  },
  event: {
    create: `${BASE_URL}/event/create`,
    update: `${BASE_URL}/event/update/`,
    delete: `${BASE_URL}/event/delete/`,
    list: `${BASE_URL}/event/list`,
    count: `${BASE_URL}/event/count`,
    aggregate: `${BASE_URL}/event/aggregate`,
    softdelete: `${BASE_URL}/event/softDelete/`,
    multisoftdelete: `${BASE_URL}/event/softDeleteMany/`,
    singlerecord: `${BASE_URL}/event/`,
  },
  follow: {
    create: `${BASE_URL}/follow/create`,
    update: `${BASE_URL}/follow/update/`,
    delete: `${BASE_URL}/follow/delete/`,
    list: `${BASE_URL}/follow/list`,
    count: `${BASE_URL}/follow/count`,
    aggregate: `${BASE_URL}/follow/aggregate`,
    softdelete: `${BASE_URL}/follow/softDelete/`,
    multisoftdelete: `${BASE_URL}/follow/softDeleteMany/`,
    singlerecord: `${BASE_URL}/follow/`,
  },
  master: {
    create: `${BASE_URL}/master/create`,
    update: `${BASE_URL}/master/update/`,
    delete: `${BASE_URL}/master/delete/`,
    list: `${BASE_URL}/master/list`,
    count: `${BASE_URL}/master/count`,
    aggregate: `${BASE_URL}/master/aggregate`,
    softdelete: `${BASE_URL}/master/softDelete/`,
    multisoftdelete: `${BASE_URL}/master/softDeleteMany/`,
    singlerecord: `${BASE_URL}/master/`,
  },
  party: {
    create: `${BASE_URL}/party/create`,
    update: `${BASE_URL}/party/update/`,
    delete: `${BASE_URL}/party/delete/`,
    list: `${BASE_URL}/party/list`,
    count: `${BASE_URL}/party/count`,
    aggregate: `${BASE_URL}/party/aggregate`,
    softdelete: `${BASE_URL}/party/softDelete/`,
    multisoftdelete: `${BASE_URL}/party/softDeleteMany/`,
    singlerecord: `${BASE_URL}/party/`,
    kycApprove: `${BASE_URL}/party/updateKYC/`,
    uploadKycDoc: `${BASE_URL}/party/updateKYCDocumentStatus/`
  },
  partySurvey: {
    create: `${BASE_URL}/partySurvey/create`,
    update: `${BASE_URL}/partySurvey/update/`,
    delete: `${BASE_URL}/partySurvey/delete/`,
    list: `${BASE_URL}/partySurvey/list`,
    count: `${BASE_URL}/partySurvey/count`,
    aggregate: `${BASE_URL}/partySurvey/aggregate`,
    softdelete: `${BASE_URL}/partySurvey/softDelete/`,
    multisoftdelete: `${BASE_URL}/partySurvey/softDeleteMany/`,
    singlerecord: `${BASE_URL}/partySurvey/`
  },
  posts: {
    create: `${BASE_URL}/posts/create`,
    update: `${BASE_URL}/posts/update/`,
    delete: `${BASE_URL}/posts/delete/`,
    list: `${BASE_URL}/posts/list`,
    count: `${BASE_URL}/posts/count`,
    aggregate: `${BASE_URL}/posts/aggregate`,
    softdelete: `${BASE_URL}/posts/softDelete/`,
    multisoftdelete: `${BASE_URL}/posts/softDeleteMany/`,
    singlerecord: `${BASE_URL}/posts/`,
  },
  projectConfig: {
    create: `${BASE_URL}/projectConfig/create`,
    update: `${BASE_URL}/projectConfig/update/`,
    delete: `${BASE_URL}/projectConfig/delete/`,
    list: `${BASE_URL}/projectConfig/list`,
    count: `${BASE_URL}/projectConfig/count`,
    aggregate: `${BASE_URL}/projectConfig/aggregate`,
    softdelete: `${BASE_URL}/projectConfig/softDelete/`,
    multisoftdelete: `${BASE_URL}/projectConfig/softDeleteMany/`,
    singlerecord: `${BASE_URL}/projectConfig/`,
  },
  questionaire: {
    create: `${BASE_URL}/questionaire/create`,
    update: `${BASE_URL}/questionaire/update/`,
    delete: `${BASE_URL}/questionaire/delete/`,
    list: `${BASE_URL}/questionaire/list`,
    count: `${BASE_URL}/questionaire/count`,
    aggregate: `${BASE_URL}/questionaire/aggregate`,
    softdelete: `${BASE_URL}/questionaire/softDelete/`,
    multisoftdelete: `${BASE_URL}/questionaire/softDeleteMany/`,
    singlerecord: `${BASE_URL}/questionaire/`,
  },
  state: {
    create: `${BASE_URL}/state/create`,
    update: `${BASE_URL}/state/update/`,
    delete: `${BASE_URL}/state/delete/`,
    list: `${BASE_URL}/state/list`,
    count: `${BASE_URL}/state/count`,
    aggregate: `${BASE_URL}/state/aggregate`,
    softdelete: `${BASE_URL}/state/softDelete/`,
    multisoftdelete: `${BASE_URL}/state/softDeleteMany/`,
    singlerecord: `${BASE_URL}/state/`,
  },
  handler: {
    create: `${BASE_URL}/handler/create`,
    update: `${BASE_URL}/handler/update/`,
    delete: `${BASE_URL}/handler/delete/`,
    list: `${BASE_URL}/handler/list`,
    count: `${BASE_URL}/handler/count`,
    aggregate: `${BASE_URL}/handler/aggregate`,
    softdelete: `${BASE_URL}/handler/softDelete/`,
    multisoftdelete: `${BASE_URL}/handler/softDeleteMany/`,
    singlerecord: `${BASE_URL}/handler/`,
    multiimport: `${BASE_URL}/handler/bulkUpload`,
    multiImportWithUser: `${BASE_URL}/handler/bulkUploadWithUser`,
    activateHandler: `${BASE_URL}/handler/updateActiveStatus/`,
    allocateHandler: `${BASE_URL}/handler/updateAllocateStatus/`,
  },
  user: {
    create: `${BASE_URL}/user/create`,
    update: `${BASE_URL}/user/update/`,
    delete: `${BASE_URL}/user/delete/`,
    list: `${BASE_URL}/user/list`,
    count: `${BASE_URL}/user/count`,
    aggregate: `${BASE_URL}/user/aggregate`,
    softdelete: `${BASE_URL}/user/softDelete/`,
    multisoftdelete: `${BASE_URL}/user/softDeleteMany/`,
    singlerecord: `${BASE_URL}/user/`,
    suspend_unSuspend:`${BASE_URL}/user/suspend-user`
  },
  userActivity: {
    create: `${BASE_URL}/userActivity/create`,
    update: `${BASE_URL}/userActivity/update/`,
    delete: `${BASE_URL}/userActivity/delete/`,
    list: `${BASE_URL}/userActivity/list`,
    count: `${BASE_URL}/userActivity/count`,
    aggregate: `${BASE_URL}/userActivity/aggregate`,
    softdelete: `${BASE_URL}/userActivity/softDelete/`,
    multisoftdelete: `${BASE_URL}/userActivity/softDeleteMany/`,
    singlerecord: `${BASE_URL}/userActivity/`,
  },
  wef: {
    create: `${BASE_URL}/wef/create`,
    update: `${BASE_URL}/wef/update/`,
    delete: `${BASE_URL}/wef/delete/`,
    list: `${BASE_URL}/wef/list`,
    count: `${BASE_URL}/wef/count`,
    aggregate: `${BASE_URL}/wef/aggregate`,
    softdelete: `${BASE_URL}/wef/softDelete/`,
    multisoftdelete: `${BASE_URL}/wef/softDeleteMany/`,
    singlerecord: `${BASE_URL}/wef/`,
  },
  Agent: {
    create: `${BASE_URL}/agent/create`,
    update: `${BASE_URL}/agent/update/`,
    delete: `${BASE_URL}/agent/delete/`,
    list: `${BASE_URL}/agent/list`,
    aggregate: `${BASE_URL}/agent/aggregate`,
    softdelete: `${BASE_URL}/agent/softDelete/`,
    multisoftdelete: `${BASE_URL}/agent/softDeleteMany/`,
    singlerecord: `${BASE_URL}/agent/`,
    suspend_unSuspend:`${BASE_URL}/agent/suspend-agent`,
    kycApprove: `${BASE_URL}/agent/updateKYC/`,
    uploadKycDoc: `${BASE_URL}/agent/updateKYCDocumentStatus/`
  },
  AgentLadger: {
    create: `${BASE_URL}/agentLadger/create`,
    update: `${BASE_URL}/agentLadger/update/`,
    delete: `${BASE_URL}/agentLadger/delete/`,
    list: `${BASE_URL}/agentLadger/list`,
    aggregate: `${BASE_URL}/agentLadger/aggregate`,
    softdelete: `${BASE_URL}/agentLadger/softDelete/`,
    multisoftdelete: `${BASE_URL}/agentLadger/softDeleteMany/`,
    singlerecord: `${BASE_URL}/agentLadger/`
  },
  RssFeed: {
    create: `${BASE_URL}/rssFeed/create`,
    update: `${BASE_URL}/rssFeed/update/`,
    delete: `${BASE_URL}/rssFeed/delete/`,
    list: `${BASE_URL}/rssFeed/list`,
    count: `${BASE_URL}/rssFeed/count`,
    aggregate: `${BASE_URL}/rssFeed/aggregate`,
    softdelete: `${BASE_URL}/rssFeed/softDelete/`,
    multisoftdelete: `${BASE_URL}/rssFeed/softDeleteMany/`,
    singlerecord: `${BASE_URL}/rssFeed/`,
  },
  Inquiry: {
    create: `${BASE_URL}/supportAndInquiry/create`,
    update: `${BASE_URL}/supportAndInquiry/update/`,
    delete: `${BASE_URL}/supportAndInquiry/delete/`,
    list: `${BASE_URL}/supportAndInquiry/list`,
    count: `${BASE_URL}/supportAndInquiry/count`,
    aggregate: `${BASE_URL}/supportAndInquiry/aggregate`,
    softdelete: `${BASE_URL}/supportAndInquiry/softDelete/`,
    multisoftdelete: `${BASE_URL}/supportAndInquiry/softDeleteMany/`,
    singlerecord: `${BASE_URL}/supportAndInquiry/`,
  },
  Achievement: {
    create: `${BASE_URL}/achievement/create`,
    update: `${BASE_URL}/achievement/update/`,
    delete: `${BASE_URL}/achievement/delete/`,
    list: `${BASE_URL}/achievement/list`,
    count: `${BASE_URL}/achievement/count`,
    aggregate: `${BASE_URL}/achievement/aggregate`,
    softdelete: `${BASE_URL}/achievement/softDelete/`,
    multisoftdelete: `${BASE_URL}/achievement/softDeleteMany/`,
    singlerecord: `${BASE_URL}/achievement/`,
  },
  ProfileReporting:{
    create: `${BASE_URL}/profileReport/create`,
    update: `${BASE_URL}/profileReport/update/`,
    delete: `${BASE_URL}/profileReport/delete/`,
    list: `${BASE_URL}/profileReport/list`,
    count: `${BASE_URL}/profileReport/count`,
    aggregate: `${BASE_URL}/profileReport/aggregate`,
    softdelete: `${BASE_URL}/profileReport/softDelete/`,
    multisoftdelete: `${BASE_URL}/profileReport/softDeleteMany/`,
    singlerecord: `${BASE_URL}/profileReport/`,
  },
  ReportReason:{
    create: `${BASE_URL}/reportReason/create`,
    update: `${BASE_URL}/reportReason/update/`,
    delete: `${BASE_URL}/reportReason/delete/`,
    list: `${BASE_URL}/reportReason/list`,
    count: `${BASE_URL}/reportReason/count`,
    aggregate: `${BASE_URL}/reportReason/aggregate`,
    softdelete: `${BASE_URL}/reportReason/softDelete/`,
    multisoftdelete: `${BASE_URL}/reportReason/softDeleteMany/`,
    singlerecord: `${BASE_URL}/reportReason/`,
  },
  PostReporting:{
    create: `${BASE_URL}/postReport/create`,
    update: `${BASE_URL}/postReport/update/`,
    delete: `${BASE_URL}/postReport/delete/`,
    list: `${BASE_URL}/postReport/list`,
    count: `${BASE_URL}/postReport/count`,
    aggregate: `${BASE_URL}/postReport/aggregate`,
    softdelete: `${BASE_URL}/postReport/softDelete/`,
    multisoftdelete: `${BASE_URL}/postReport/softDeleteMany/`,
    singlerecord: `${BASE_URL}/postReport/`,
  },
  role: {
    list: `${BASE_URL}/role/list`,
  },
  userRole: {
    create: `${BASE_URL}/userRole/create`,
    list: `${BASE_URL}/userRole/list`,
    softdelete: `${BASE_URL}/userRole/softDelete/`,
  },
  configuration: {
    get: `${BASE_URL}/configuration/get`,
    create: `${BASE_URL}/configuration/create`,
  },

};
