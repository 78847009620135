import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { follow } = API_URLS;

export const listFollows = (payload) => {
  return apiClient({ url: follow.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createFollow = (payload) => {
  return apiClient({ url: follow.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateFollow = (payload) => {
  return apiClient({
    url: follow.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteFollow = (payload) => {
  return apiClient({
    url: follow.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteFollow = (payload) => {
  return apiClient({
    url: follow.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getFollowCount = () => {
  return apiClient({ url: follow.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getFollowAggregate = (payload) => {
  return apiClient({ url: follow.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getFollowById = (payload) => {
  return apiClient({
    url: follow.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleFollow = (payload) => {
  return apiClient({
    url: follow.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
