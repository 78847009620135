import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listContracts,
  createContract,
  updateContract,
  softDeleteContract,
  softDeleteMultipleContract,
  deleteContract,
  getContractAggregate,
  getContractById,
  getContractCount,
} from "../services/Contract.service";

function useContractList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Contract", { page, limit, $and, sort }], () =>
    listContracts(args)
  );
}

function useContractCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createContract(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Contract"]);

      const previousValue = queryClient.getQueryData(["Contract"]) || [];

      queryClient.setQueryData(["Contract"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Contract"], previousValue),
    // After success or failure, refetch the Contracts query
    onSettled: () => {
      queryClient.invalidateQueries(["Contract"]);
    },
  });
}

function useContractUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateContract(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Contract"]);

      const previousValue = queryClient.getQueryData(["Contract"]);

      queryClient.setQueryData(["Contract"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Contract"], previousValue),
    // After success or failure, refetch the Contracts query
    onSettled: () => {
      queryClient.invalidateQueries(["Contract"]);
    },
  });
}

function useContractSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteContract(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Contract"]);

      const previousValue = queryClient.getQueryData(["Contract"]) || [];
      queryClient.setQueryData(["Contract"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Contract"], previousValue),
    // After success or failure, refetch the Contracts query
    onSettled: () => {
      queryClient.invalidateQueries(["Contract"]);
    },
  });
}

function useContractMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleContract(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Contract"]);

      const previousValue = queryClient.getQueryData(["Contract"]) || [];
      queryClient.setQueryData(["Contract"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Contract"], previousValue),
    // After success or failure, refetch the Contracts query
    onSettled: () => {
      queryClient.invalidateQueries(["Contract"]);
    },
  });
}

function useContractDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteContract(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Contract"]);

      const previousValue = queryClient.getQueryData(["Contract"]) || [];
      queryClient.setQueryData(["Contract"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Contract"], previousValue),
    // After success or failure, refetch the Contracts query
    onSettled: () => {
      queryClient.invalidateQueries(["Contract"]);
    },
  });
}

function useContractCount() {
  return useQuery(["ContractCount"], () => {
    return getContractCount();
  });
}

function useContractAggregate(record) {
  return useQuery("Contract", () => {
    return getContractAggregate(record);
  });
}

function useContractGetById(id) {
  return useQuery(["Contract", id], () => {
    return getContractById(id);
  });
}

export {
  useContractList,
  useContractCreate,
  useContractUpdate,
  useContractDelete,
  useContractMultipleSoftDelete,
  useContractCount,
  useContractSoftDelete,
  useContractAggregate,
  useContractGetById,
};
