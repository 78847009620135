import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listPartys,
  createParty,
  updateParty,
  softDeleteParty,
  softDeleteMultipleParty,
  deleteParty,
  getPartyAggregate,
  getPartyById,
  getPartyCount,
  changePartyKYCStatus,
  changeKYCDocumentStatus
} from "../services/Party.service";

function usePartyList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};
    

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Party", { page, limit, $and, sort }], () =>
    listPartys(args)
  );
}

function usePartyCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createParty(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];

      queryClient.setQueryData(["Party"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function usePartyUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateParty(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]);

      queryClient.setQueryData(["Party"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function usePartySoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteParty(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];
      queryClient.setQueryData(["Party"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function usePartyMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleParty(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];
      queryClient.setQueryData(["Party"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function useApprovePartyKYC() {
  const queryClient = useQueryClient();
  return useMutation((record) => changePartyKYCStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];
      queryClient.setQueryData(["Party"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function useUpdateKYCDocument() {
  console.log("useUpdateKYCDocument")
  const queryClient = useQueryClient();
  return useMutation((record) => changeKYCDocumentStatus(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];
      queryClient.setQueryData(["Party"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function usePartyDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteParty(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Party"]);

      const previousValue = queryClient.getQueryData(["Party"]) || [];
      queryClient.setQueryData(["Party"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Party"], previousValue),
    // After success or failure, refetch the Partys query
    onSettled: () => {
      queryClient.invalidateQueries(["Party"]);
    },
  });
}

function usePartyCount() {
  return useQuery(["PartyCount"], () => {
    return getPartyCount();
  });
}

function usePartyAggregate(record) {
  return useQuery("Party", () => {
    return getPartyAggregate(record);
  });
}

function usePartyGetById(id) {
  return useQuery(["Party", id], () => {
    return getPartyById(id);
  });
}

export {
  usePartyList,
  usePartyCreate,
  usePartyUpdate,
  usePartyDelete,
  usePartyMultipleSoftDelete,
  usePartyCount,
  usePartySoftDelete,
  usePartyAggregate,
  usePartyGetById,
  useApprovePartyKYC,
  useUpdateKYCDocument
};
