import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listMasters,
  createMaster,
  updateMaster,
  softDeleteMaster,
  softDeleteMultipleMaster,
  deleteMaster,
  getMasterAggregate,
  getMasterById,
  getMasterCount,
} from "../services/Master.service";

function useMasterList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["Master", { page, limit, $and, sort }], () =>
    listMasters(args)
  );
}

function useMasterCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createMaster(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["Master"]);

      const previousValue = queryClient.getQueryData(["Master"]) || [];

      queryClient.setQueryData(["Master"], () => [...previousValue, newRecord]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Master"], previousValue),
    // After success or failure, refetch the Masters query
    onSettled: () => {
      queryClient.invalidateQueries(["Master"]);
    },
  });
}

function useMasterUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateMaster(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["Master"]);

      const previousValue = queryClient.getQueryData(["Master"]);

      queryClient.setQueryData(["Master"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Master"], previousValue),
    // After success or failure, refetch the Masters query
    onSettled: () => {
      queryClient.invalidateQueries(["Master"]);
    },
  });
}

function useMasterSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMaster(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Master"]);

      const previousValue = queryClient.getQueryData(["Master"]) || [];
      queryClient.setQueryData(["Master"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Master"], previousValue),
    // After success or failure, refetch the Masters query
    onSettled: () => {
      queryClient.invalidateQueries(["Master"]);
    },
  });
}

function useMasterMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleMaster(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Master"]);

      const previousValue = queryClient.getQueryData(["Master"]) || [];
      queryClient.setQueryData(["Master"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Master"], previousValue),
    // After success or failure, refetch the Masters query
    onSettled: () => {
      queryClient.invalidateQueries(["Master"]);
    },
  });
}

function useMasterDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteMaster(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["Master"]);

      const previousValue = queryClient.getQueryData(["Master"]) || [];
      queryClient.setQueryData(["Master"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["Master"], previousValue),
    // After success or failure, refetch the Masters query
    onSettled: () => {
      queryClient.invalidateQueries(["Master"]);
    },
  });
}

function useMasterCount() {
  return useQuery(["MasterCount"], () => {
    return getMasterCount();
  });
}

function useMasterAggregate(record) {
  return useQuery("Master", () => {
    return getMasterAggregate(record);
  });
}

function useMasterGetById(id) {
  return useQuery(["Master", id], () => {
    return getMasterById(id);
  });
}

export {
  useMasterList,
  useMasterCreate,
  useMasterUpdate,
  useMasterDelete,
  useMasterMultipleSoftDelete,
  useMasterCount,
  useMasterSoftDelete,
  useMasterAggregate,
  useMasterGetById,
};
