import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { complaint } = API_URLS;

export const listComplaints = (payload) => {
  return apiClient({ url: complaint.list, data: payload })
    .then((res) => res?.data===null?{}:res?.data===null?{}:res?.data===null?{}:res?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createComplaint = (payload) => {
  return apiClient({ url: complaint.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateComplaint = (payload) => {
  return apiClient({
    url: complaint.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteComplaint = (payload) => {
  return apiClient({
    url: complaint.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteComplaint = (payload) => {
  return apiClient({
    url: complaint.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getComplaintCount = () => {
  return apiClient({ url: complaint.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getComplaintAggregate = (payload) => {
  return apiClient({ url: complaint.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getComplaintById = (payload) => {
  return apiClient({
    url: complaint.singlerecord + payload,
    data: { query: { isActive: true, isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleComplaint = (payload) => {
  return apiClient({
    url: complaint.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
