import { apiClient } from "./../api/client";
import { API_URLS } from "../api/config";
const { handler } = API_URLS;

export const listHandlers = (payload) => {
  return apiClient({ url: handler.list, data: payload })
    .then((res) =>
    res ?.data===null?{} :res?.data===null?{} : res?.data===null?{}:res?.data
    )
    //console.log("",res)
    .catch((err) => {
      console.log("err",err)
      throw new Error(err);
    });
};

export const createHandler = (payload) => {
  return apiClient({ url: handler.create, data: payload })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.data?.message);
    });
};

export const updateHandler = (payload) => {
  return apiClient({
    url: handler.update + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err?.data?.message || "Can't update record.");
    });
};

export const softDeleteHandler = (payload) => {
  return apiClient({
    url: handler.softdelete + payload.id,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteHandler = (payload) => {
  return apiClient({
    url: handler.delete + payload.id,
    data: payload,
    method: "DELETE",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHandlerCount = () => {
  return apiClient({ url: handler.count })
    .then((res) => res.data?.totalRecords || 0)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHandlerAggregate = (payload) => {
  return apiClient({ url: handler.aggregation, data: payload })
    .then((res) => res.data?.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHandlerById = (payload) => {
  return apiClient({
    url: handler.singlerecord + payload,
    data: { query: { isDeleted: false } },
    method: "GET",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const softDeleteMultipleHandler = (payload) => {
  return apiClient({
    url: handler.multisoftdelete,
    data: payload,
    method: "PUT",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const importMultipleHandler = (payload) => {
  return apiClient({
    url: handler.multiimport,
    data: payload,
    method: "POST",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const importMultipleHandlerWithUser = (payload) => {
  return apiClient({
    url: handler.multiImportWithUser,
    data: payload,
    method: "POST",
  })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setHandlerActivateById = (payload) => {
  return apiClient({
    url: handler.activateHandler + payload.id,
    data: null,
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setHandlerAllocateById = (payload) => {
  console.log("setHandlerAllocateById")
  return apiClient({
    url: handler.allocateHandler + payload.id,
    data: { query: { isAllocate:true } },
    method: "PUT",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};