
    import React from 'react';
    import { useAgentSuspend,useAgentCreate, useAgentSoftDelete,useAgentMultipleSoftDelete, useAgentUpdate,useApproveAgentKYC,useUpdateKYCDocument  } from '../../queries/Agent.queries';
    import Agent from './Agent';
    
    const AgentContainer = () => {
    
      const {mutate:addRecord} = useAgentCreate();
      const {mutate:editRecord} = useAgentUpdate();
      const {mutate:deleteRecord} = useAgentSoftDelete();
      const {mutate:deleteRecords} = useAgentMultipleSoftDelete();
      const {mutate:agentSuspend} = useAgentSuspend();
      const {mutate:approveKYC} = useApproveAgentKYC();
      const {mutate:verifyKYCDocumentUpload} = useUpdateKYCDocument();
    
      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          console.log("record",record)
          record.agentCode = record.phone
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };
    
      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
    
      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record, 
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onAgentSuspend = (record) => {
        return new Promise((resolve, reject) => {
          record.isBlocked = true
          agentSuspend(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onApproveKYC = (record) => {
        return new Promise((resolve, reject) => {
          approveKYC(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onKYCDocumentUpload = (record) => {
        return new Promise((resolve, reject) => {
          console.log("sgr")
          verifyKYCDocumentUpload(
            record, 
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (
        <Agent 
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
          agentSuspend={onAgentSuspend}
          approveKYC={onApproveKYC}
          verifyKYCDocumentUpload ={onKYCDocumentUpload}
        />
      )
    }
    
    export default AgentContainer;
    