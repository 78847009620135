import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const PartySurveyReducer = createSlice({
  name: "PartySurvey",
  initialState,
  reducers: {
    defaultAction: (state, { payload }) => {
      console.log("PartySurveyReducer state and payload", state, payload);
    },
  },
});

const { reducer, actions } = PartySurveyReducer;
export const { defaultAction } = actions;
export default reducer;
