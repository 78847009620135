import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listPartySurveys,
  createPartySurvey,
  updatePartySurvey,
  softDeletePartySurvey,
  softDeleteMultiplePartySurvey,
  deletePartySurvey,
  getPartySurveyAggregate,
  getPartySurveyById,
  getPartySurveyCount,
} from "../services/PartySurvey.service";

function usePartySurveyList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["PartySurvey", { page, limit, $and, sort }], () =>
    listPartySurveys(args)
  );
}

function usePartySurveyCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createPartySurvey(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["PartySurvey"]);

      const previousValue = queryClient.getQueryData(["PartySurvey"]) || [];

      queryClient.setQueryData(["PartySurvey"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PartySurvey"], previousValue),
    // After success or failure, refetch the PartySurveys query
    onSettled: () => {
      queryClient.invalidateQueries(["PartySurvey"]);
    },
  });
}

function usePartySurveyUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updatePartySurvey(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["PartySurvey"]);

      const previousValue = queryClient.getQueryData(["PartySurvey"]);

      queryClient.setQueryData(["PartySurvey"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PartySurvey"], previousValue),
    // After success or failure, refetch the PartySurveys query
    onSettled: () => {
      queryClient.invalidateQueries(["PartySurvey"]);
    },
  });
}

function usePartySurveySoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeletePartySurvey(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PartySurvey"]);

      const previousValue = queryClient.getQueryData(["PartySurvey"]) || [];
      queryClient.setQueryData(["PartySurvey"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PartySurvey"], previousValue),
    // After success or failure, refetch the PartySurveys query
    onSettled: () => {
      queryClient.invalidateQueries(["PartySurvey"]);
    },
  });
}

function usePartySurveyMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultiplePartySurvey(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PartySurvey"]);

      const previousValue = queryClient.getQueryData(["PartySurvey"]) || [];
      queryClient.setQueryData(["PartySurvey"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PartySurvey"], previousValue),
    // After success or failure, refetch the PartySurveys query
    onSettled: () => {
      queryClient.invalidateQueries(["PartySurvey"]);
    },
  });
}

function usePartySurveyDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deletePartySurvey(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["PartySurvey"]);

      const previousValue = queryClient.getQueryData(["PartySurvey"]) || [];
      queryClient.setQueryData(["PartySurvey"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["PartySurvey"], previousValue),
    // After success or failure, refetch the PartySurveys query
    onSettled: () => {
      queryClient.invalidateQueries(["PartySurvey"]);
    },
  });
}

function usePartySurveyCount() {
  return useQuery(["PartySurveyCount"], () => {
    return getPartySurveyCount();
  });
}

function usePartySurveyAggregate(record) {
  return useQuery("PartySurvey", () => {
    return getPartySurveyAggregate(record);
  });
}

function usePartySurveyGetById(id) {
  return useQuery(["PartySurvey", id], () => {
    return getPartySurveyById(id);
  });
}

export {
  usePartySurveyList,
  usePartySurveyCreate,
  usePartySurveyUpdate,
  usePartySurveyDelete,
  usePartySurveyMultipleSoftDelete,
  usePartySurveyCount,
  usePartySurveySoftDelete,
  usePartySurveyAggregate,
  usePartySurveyGetById,
};
