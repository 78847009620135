import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  listAppVersions,
  createAppVersion,
  updateAppVersion,
  softDeleteAppVersion,
  softDeleteMultipleAppVersion,
  deleteAppVersion,
  getAppVersionAggregate,
  getAppVersionById,
  getAppVersionCount,
} from "../services/AppVersion.service";

function useAppVersionList(args) {
  const { page, limit } = args.options;
  let $and = [],
    sort = {};

  if (args.query?.$and) {
    $and = { ...args.query?.$and };
  }
  if (args.options?.sort) {
    sort = { ...args.options?.sort };
  }else{
    args.options.sort = {_id:-1}
  }
  return useQuery(["AppVersion", { page, limit, $and, sort }], () =>
    listAppVersions(args)
  );
}

function useAppVersionCreate() {
  const queryClient = useQueryClient();
  return useMutation((record) => createAppVersion(record), {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["AppVersion"]);

      const previousValue = queryClient.getQueryData(["AppVersion"]) || [];

      queryClient.setQueryData(["AppVersion"], () => [
        ...previousValue,
        newRecord,
      ]);
      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AppVersion"], previousValue),
    // After success or failure, refetch the AppVersions query
    onSettled: () => {
      queryClient.invalidateQueries(["AppVersion"]);
    },
  });
}

function useAppVersionUpdate() {
  const queryClient = useQueryClient();
  return useMutation((record) => updateAppVersion(record), {
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(["AppVersion"]);

      const previousValue = queryClient.getQueryData(["AppVersion"]);

      queryClient.setQueryData(["AppVersion"], (old) => {
        return old?.map((oldData) => {
          if (oldData.id === updatedData.id) return updatedData;
          else return oldData;
        });
      });
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AppVersion"], previousValue),
    // After success or failure, refetch the AppVersions query
    onSettled: () => {
      queryClient.invalidateQueries(["AppVersion"]);
    },
  });
}

function useAppVersionSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteAppVersion(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AppVersion"]);

      const previousValue = queryClient.getQueryData(["AppVersion"]) || [];
      queryClient.setQueryData(["AppVersion"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AppVersion"], previousValue),
    // After success or failure, refetch the AppVersions query
    onSettled: () => {
      queryClient.invalidateQueries(["AppVersion"]);
    },
  });
}

function useAppVersionMultipleSoftDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => softDeleteMultipleAppVersion(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AppVersion"]);

      const previousValue = queryClient.getQueryData(["AppVersion"]) || [];
      queryClient.setQueryData(["AppVersion"], (oldData) =>
        previousValue.filter((record) => !deletedRecord.ids.includes(record.id))
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AppVersion"], previousValue),
    // After success or failure, refetch the AppVersions query
    onSettled: () => {
      queryClient.invalidateQueries(["AppVersion"]);
    },
  });
}

function useAppVersionDelete() {
  const queryClient = useQueryClient();
  return useMutation((record) => deleteAppVersion(record), {
    onMutate: async (deletedRecord) => {
      await queryClient.cancelQueries(["AppVersion"]);

      const previousValue = queryClient.getQueryData(["AppVersion"]) || [];
      queryClient.setQueryData(["AppVersion"], (oldData) =>
        previousValue.filter((record) => record.id !== deletedRecord.id)
      );
      return previousValue;
    },

    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(["AppVersion"], previousValue),
    // After success or failure, refetch the AppVersions query
    onSettled: () => {
      queryClient.invalidateQueries(["AppVersion"]);
    },
  });
}

function useAppVersionCount() {
  return useQuery(["AppVersionCount"], () => {
    return getAppVersionCount();
  });
}

function useAppVersionAggregate(record) {
  return useQuery("AppVersion", () => {
    return getAppVersionAggregate(record);
  });
}

function useAppVersionGetById(id) {
  return useQuery(["AppVersion", id], () => {
    return getAppVersionById(id);
  });
}

export {
  useAppVersionList,
  useAppVersionCreate,
  useAppVersionUpdate,
  useAppVersionDelete,
  useAppVersionMultipleSoftDelete,
  useAppVersionCount,
  useAppVersionSoftDelete,
  useAppVersionAggregate,
  useAppVersionGetById,
};
