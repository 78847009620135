import * as React from "react";
import ModelJsonSchema from "./Handler.schema.json";
import mockData from "./data.json";
import { useBoolean } from "./../../hooks";
import { AddDrawer } from "../../components/common/AddDrawer";
import { EditDrawer } from "../../components/common/EditDrawer";
import { Confirmation } from "../../components/common/Confirmation";
import { errorToast, successToast } from "../../utils/notifications";
import HandlerTable from "./HandlerTable";
import { ACTION_TYPE, STORAGE_KEY } from "../../constant/common";
import { checkPermission } from "../../api/general";
import { isMocking } from "../../utils/utils";
import XLSX from 'xlsx';
import { SummaryListView } from "../../components/common/SummaryListView"
const formSchema = {
  databaseType: process.env.REACT_APP_DATABASE_TYPE,
  screenLayout: ModelJsonSchema.screenLayout,
  attributes:
    ModelJsonSchema.actions.find((action) => action.category === "addEdit")
      ?.attributes || [],
};

const Handler = ({ addRecord, editRecord, deleteRecord, deleteRecords,importRecords,handlerActivate,handlerAllocate,importRecordsWithUser }) => {
  const [addModal, showAddModal, hideAddModal] = useBoolean(false);
  const [editModal, showEditModal, hideEditModal] = useBoolean(false);
  const [deleteModal, showDeleteModal, hideDeleteModal] = useBoolean(false);
  const [uploadModel,showUploadModel, hideUploadModel] = useBoolean(false)
  const [exportData, setExportData] =  React.useState(null);
  const [actionType, setActionType] = React.useState(null);
	const inputFile = React.useRef(null)
  const inputFileWithUser = React.useRef(null)
  const currentRecord = React.useRef({});
  const selectedRows = React.useRef({});

  const onViewRecord = React.useCallback((record) => {
  console.log("record",record)

    record.id && window.open(`handler/${record.id}`, "_blank");
  }, []);

  const onDelete = React.useCallback((record) => {
    setActionType(ACTION_TYPE.DELETE);
    currentRecord.current = record;
    showDeleteModal();
  }, []);

  const onMultiDelete = React.useCallback((selectedIds) => {
    setActionType(ACTION_TYPE.MULTIDELETE);
    selectedRows.current = selectedIds;
    showDeleteModal();
  }, []);

  const onEdit = React.useCallback((record) => {
    currentRecord.current = record;
    showEditModal();
  }, []);

  const onApprove = React.useCallback((record) => {
    currentRecord.current = record;
    handlerActivate({
      id: currentRecord.current.id
    });
  });

  const onBlueTickVerified = React.useCallback((record) => {
    currentRecord.current = record;
    editRecord({
      id: currentRecord.current.id,
      isBlueTickVerified: true,
      blueTickAt: new Date(),
      blueTickBy: localStorage.getItem(STORAGE_KEY.USER_ID)
    });
  });

  const onCustomActionClick = React.useCallback((actionName, record) => {
    alert(actionName + " action clicked");
  }, []);

  const onImport = React.useCallback((record) => {
    inputFile.current.click();

  }, []);
  const onImportWithUser = React.useCallback((record) => {
    // showUploadModel()
    inputFileWithUser.current.click();
  }, []);
  const onBlockToggle = React.useCallback((record) => {
    currentRecord.current = record;
    console.log("Handler",record)
    handlerAllocate({
      id: currentRecord.current.id,
    });
  });

const fileChange=React.useCallback((e) => {
    e.preventDefault();
	var files = e.target.files, f = files[0]
	var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:0, blankRows: false});
       importRecords(dataParse)
    };
    reader.readAsBinaryString(f)
  }, []);

  const fileChangeWithUser=React.useCallback((e) => {
    showUploadModel(true)
    e.preventDefault();
	var files = e.target.files, f = files[0]
	var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:0, blankRows: false});
        importRecordsWithUser(dataParse).then((res)=>{
          console.log("sgr",res.data)
          setExportData(res.data)
          // hideUploadModel();
        })
    };
    reader.readAsBinaryString(f)
  }, []);
  return (
    <React.Fragment>
      <HandlerTable
        onApprove={onApprove}
        // onBlueTickVerified={onBlueTickVerified}
        // mockData={mockData.data}
        onCustomActionClick={onCustomActionClick}
        onBlockToggle={onBlockToggle}
        onImport ={onImport}
        onImportWithUser ={onImportWithUser}
        onEdit={onEdit}
        onDelete={onDelete}
        onView={onViewRecord}
        onMultiDelete={onMultiDelete}
        onUpdateVarifyRecord = {handlerActivate}

        noView={

          !checkPermission({
            permissionType: "R",
            modelName: ModelJsonSchema.name,
          })
        }
        noEdit={

          !checkPermission({
            permissionType: "U",
            modelName: ModelJsonSchema.name,
          })
        }
        noDelete={

          !checkPermission({
            permissionType: "D",
            modelName: ModelJsonSchema.name,
          })
        }
      />
		<input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept=".xlsx, .xls" onChange={fileChange} />
    <input type='file' id='fileWithUser' ref={inputFileWithUser} style={{display: 'none'}} accept=".xlsx, .xls" onChange={fileChangeWithUser} />


      <Confirmation
        handleSubmit={() => {
          actionType === ACTION_TYPE.DELETE &&
            currentRecord.current.id &&
            deleteRecord({ id: currentRecord?.current?.id })
              .then(successToast)
              .catch(errorToast)
              .finally(hideDeleteModal);
          actionType === ACTION_TYPE.MULTIDELETE &&
            selectedRows.current &&
            deleteRecords({ ids: selectedRows.current })
              .then(successToast)
              .catch(errorToast)
              .finally(hideDeleteModal);
        }}
        handleCancel={hideDeleteModal}
        isOpen={deleteModal}
        OkText="Delete"
      />
       <SummaryListView
        handleCancel={hideUploadModel}
        isOpen={uploadModel}
        OkText="Summary"
        currentData={exportData}
      />
    </React.Fragment>
  );
};

export default Handler;
