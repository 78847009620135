
    import React from 'react';
    import { useHandlerCreate, useHandlerSoftDelete,useHandlerMultipleSoftDelete, useHandlerUpdate,useHandlerMultipleImport,useHandlerActivate,useHandlerAllocate,useHandlerWithUser } from '../../queries/Handler.queries';
    import Handler from './Handler';

    const HandlerContainer = () => {

      const {mutate:addRecord} = useHandlerCreate();
      const {mutate:editRecord} = useHandlerUpdate();
      const {mutate:deleteRecord} = useHandlerSoftDelete();
      const {mutate:deleteRecords} = useHandlerMultipleSoftDelete();
      const {mutate:importRecords} = useHandlerMultipleImport();
      const {mutate:handlerActivate} = useHandlerActivate();
      const {mutate:handlerAllocate} = useHandlerAllocate();
      const {mutate:importRecordsWithUser} = useHandlerWithUser();

      const onAddRecord = (record) => {
        return new Promise((resolve,reject) => {
          addRecord(record, {
            onSuccess: async () => resolve('Record created successfully.'),
            onError: async (error) => reject(error?.message),
          });
        })
      };

      const onEditRecord = (record) => {
        return new Promise((resolve, reject) => {
          editRecord(
            record,
            {
              onSuccess: async () => resolve('Record updated successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onDeleteRecord = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecord(
            record,
            {
              onSuccess: async () => resolve('Record deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      const onMultiDelete = (record) => {
        return new Promise((resolve, reject) => {
          deleteRecords(
            record,
            {
              onSuccess: async () => resolve('Records deleted successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
	  const onImportRecord = (record) => {
        return new Promise((resolve, reject) => {
          importRecords(
            record,
            {
              onSuccess: async () => resolve('Records Imported successfully.'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onImportRecordsWithUser = (record) => {
        return new Promise((resolve, reject) => {
          importRecordsWithUser(
            record,
            {
              onSuccess: async (data) => {resolve(data)},
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      
      const onHandlerActivate = (record) => {
        return new Promise((resolve, reject) => {
          handlerActivate(
            record,
            {
              onSuccess: async () => resolve('Update successfully'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };
      const onHandlerAllocate = (record) => {
        return new Promise((resolve, reject) => {
          handlerAllocate(
            record,
            {
              onSuccess: async () => resolve('Update successfully'),
              onError: async (error) => reject(error?.message),
            }
          );
        });
      };

      return (

        <Handler
          addRecord={onAddRecord}
          deleteRecord={onDeleteRecord}
          deleteRecords={onMultiDelete}
          editRecord={onEditRecord}
          importRecords={onImportRecord}
          handlerActivate={onHandlerActivate}
          handlerAllocate={onHandlerAllocate}
          importRecordsWithUser={onImportRecordsWithUser}
        />
      )
    }

    export default HandlerContainer;
